// import { useEffect, useState } from 'react';
import { trpc } from '../trpc';

type AppProps = {
  address: string;
  uuid?: string;
  token_id: number;
  onSet: Function;
};

export function SetTransfer({ address, token_id, onSet }: AppProps) {
  const uuidObj = trpc.api.createTransfer.useQuery({ address, token_id });

  if (uuidObj.data) {
    // console.log("uuidObj", uuidObj.data.uuid);
    onSet(uuidObj.data.uuid);
    return <>{ window.location.origin}/o/transfer/{uuidObj.data.uuid}</>;
  } else return <>Creating the url to transfer...</>;
}

// Couldnt do this here
export function _ExecTransfer({ address, token_id, uuid, onSet }: AppProps) {
  console.log('ExecTransfer', address, token_id, uuid);
  if (!uuid) return <></>;
  const mutator = trpc.api.execTransfer.useMutation();
  mutator.mutate({ uuid, newOwner: address });

  console.log('ExecTransfer Done ' + mutator.data);

  // if (mutator.data?.transferred){
  //   onSet(mutator.data.transferred);
  //   return <>{mutator.data.transferred}</>;
  // } else
  return <></>;
}

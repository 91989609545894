import React from 'react';
import { createRoot } from 'react-dom/client';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import App from './App';
// import { authClient } from './auth';
import * as serviceWorker from './serviceWorker';
import './scss/main.scss';
// import AOS from 'aos';

import ReactGA4 from 'react-ga4';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

async function main() {
  // https://github.com/codler/react-ga4
  ReactGA4.initialize(import.meta.env.VITE_GA4);

  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [new BrowserTracing({ tracingOrigins: ['*'] })],
    environment: import.meta.env.MODE,
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
    beforeSend: (event) => {
      if (import.meta.env.MODE === 'development') {
        return null;
      }
      return event;
    },
  });

  const container = document.getElementById('root');
  const root = createRoot(container!);
  root.render(
    <div>
      {/* <App auth={authClient} /> */}
      <App />
      <ToastContainer />
    </div>,
  );
}

main().catch((err) => {
  console.error('Error starting app');
  console.error(err);
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { trpc } from '../trpc';
import { useBodyClassName } from '../react-helpers';

import { getBeneficiary, getEventInfo, getIncTokenInfo, getTokenInfo, incQrRequest } from '@/api';

import browserLocalstorage from 'browser-localstorage-expire';
import { SubmitButton } from '@/components/SubmitButton';
import { reduceAddress } from '@/lib/helpers';
import { eventInfoValues } from '@/lib/constants';
import PoapImage from '@/components/PoapImage';

export const IncTransferPage = () => {
  const [exec, setExec] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const [address, setAddress] = useState<string>();
  const [eventInfo, setEventInfo] = useState<eventInfoValues>();

  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  // const localCache = browserLocalstorage();

  if (!params.uuid) return <>No uuid found.</>;

  const selector = trpc.api.getTransfer.useQuery({ uuid: params.uuid });
  const mutator = trpc.api.execTransfer.useMutation();

  const calledOnce = useRef<boolean>(true);

  useBodyClassName('poap-app');

  useEffect(() => {
    if (calledOnce.current === false) return;
    calledOnce.current = false;

    document.title = 'POAP (POAP transfer)';

    if (location.state) {
      setAddress(location.state);
      console.log(`setState address ${location.state}`);
      return;
    } else {
      setAddress('');
      return;
    }

    // const prevTokenId: string | null = localCache.getItem('incHash');
    // if (prevTokenId) {
    //   getBeneficiary(prevTokenId).then((prevTokenInfo) => {
    //     if (prevTokenInfo.beneficiary) {
    //       setAddress(prevTokenInfo.beneficiary);
    //       console.log('address from hash: ' + prevTokenInfo.beneficiary);
    //     }
    //   });
    // } else {
    // navigate(`/o/login`, { state: window.location.pathname });
    // }
  });

  useEffect(() => {
    if (selector.data) {
      getIncTokenInfo(selector.data.token_id)
        .then((v) => {
          console.log(v.event);
          //@ts-ignore
          setEventInfo(v.event);
        })
        .catch((e) => {
          const er: Error = e as Error;
          // setincUrlAvailable(er.message);
          console.log('err');
        });
      if (selector.data.current_owner === address) {
        // console.error(selector.data?.current_owner, address)
        setError(
          `The token (${selector.data?.token_id}) to be transfered is yours. Please share this url with your friend.`,
        );
      }
    }
  }, [selector, address]);

  useEffect(() => {
    if (eventInfo && address === '')
      navigate(`/o/auth/${eventInfo.id}`, {
        state: {
          url: window.location.pathname,
          message: `For transfer, you have to login`,
        },
      });
  }, [eventInfo, address]);

  useEffect(() => {
    if (exec === false) return;

    if (address && params.uuid) {
      mutator.mutate({
        uuid: params.uuid,
        newOwner: address,
      });
    }
  }, [exec]);

  return (
    <>
      {eventInfo && <PoapImage claim={{ event: { image_url: eventInfo.image_url, name: eventInfo.name } }} />}
      <hr />
      {error ? (
        <>{error}</>
      ) : !address ? (
        <>No address found</>
      ) : selector.data && selector.data.new_owner === null ? (
        <>
          <>
            The token ({selector.data.token_id}) is currently owned by {reduceAddress(selector.data.current_owner)}
          </>
          <br />
          <>It will be transfered to you ({reduceAddress(address)})</>
          <br />
          <SubmitButton
            text={'transfer'}
            onClick={() => {
              setExec(true);
            }}
            style={{
              color: '#fff',
              backgroundColor: '#000',
              border: 'solid 2px white',
            }}
            isSubmitting={false}
            canSubmit
          />

          <br />
          {!mutator.isLoading && JSON.stringify(mutator.data)}
          {mutator.error && <p>Something went wrong! {mutator.error.message}</p>}
        </>
      ) : selector.data && selector.data.new_owner === address ? (
        <>
          <>The token ({selector.data.token_id}) is transfered!</>
          <br />
          <SubmitButton
            text={'To your POEMs'}
            onClick={() => {
              navigate(`/o/scan/${address}`);
            }}
            style={{
              color: '#fff',
              backgroundColor: '#000',
              border: 'solid 2px white',
            }}
            isSubmitting={false}
            canSubmit
          />
        </>
      ) : (
        <>Something wrong.</>
      )}
    </>
  );
};

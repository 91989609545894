import React, { ChangeEventHandler, useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { useI18nContext } from '@/i18n/i18n-react';
import type { Locales } from '@/i18n/i18n-types';
import { locales } from '@/i18n/i18n-util';
import { loadLocaleAsync } from '@/i18n/i18n-util.async';

import { useBodyClassName } from '../react-helpers';

// https://github.com/devmehq/react-qr-code
// import { ReactQrCode } from '@devmehq/react-qr-code';
import { QRCode } from 'react-qrcode-logo';

import WaPoapLogo from '../images/POEM-logo-new-w-with-Back.png';
import CustomComponent from './CustomCompoment';
import { getEventInfo } from '@/api';
import { addToast } from '@/components/Toast';
import { TemplateClaimFooter } from '@/incClaimPage/templateClaim/TemplateClaimFooter';
import Closed from '@/components/Closed';
// import { TemplateClaimHeader } from '@/incClaimPage/templateClaim/TemplateClaimHeader';

export const IncQrPage = () => {
  const [remainNum, setRemainNum] = useState<number>(-1);
  const [totalNum, setTotalNum] = useState<number>(-1);
  const [imageUrl, setImageUrl] = useState<{
    name: string;
    image_url: string;
  }>({
    name: '',
    image_url: '',
  });

  useBodyClassName('poap-app');
  const params = useParams();
  let _eventId = params.eventId;

  const [searchParams] = useSearchParams();

  const navigate = useNavigate();

  const nextUrl = searchParams.get("connect") ? `/o/url/${_eventId}` : `/o/auth/${_eventId}`;

  const { locale, LL, setLocale } = useI18nContext();

  const onLocaleSelected: ChangeEventHandler<HTMLSelectElement> = async ({ target }) => {
    const locale = target.value as Locales;
    localStorage.setItem('lang', locale);
    await loadLocaleAsync(locale);
    setLocale(locale);
  };

  useEffect(() => {
    // if (remainNum !== 0) return;
    // console.log(remainNum);
    (async () => {
      if (_eventId) {
        let eventInfo = await getEventInfo(_eventId);
        setImageUrl({
          //@ts-ignore
          image_url: eventInfo.image_url,
          //@ts-ignore
          name: eventInfo.name,
        });
        console.log(eventInfo);
      }
    })();
  }, [remainNum]); // eslint-disable-line

// Convert canvas to image
function downloadImage() {
  const canvas = document.querySelector('#react-qrcode-logo');

  if (canvas) {
    //@ts-ignore
    var dataURL = canvas.toDataURL("image/jpeg", 1.0);
    _downloadImage(dataURL, `${_eventId}_${imageUrl.name}.jpg`);
  } else {
    console.error("Cannot save the canvas as jpg")
  }
};

// Save | Download image
  //@ts-ignore
function _downloadImage(data, filename = 'untitled.jpeg') {
  var a = document.createElement('a');
  a.href = data;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
}

  return (
    <main id="site-main" role="main" className="app-content">
      {/* <TemplateClaimHeader /> */}
      {remainNum !== 0 ? (
        <div className="container">
          <p style={{ textAlign: 'center' }}>{LL.HI({ name: imageUrl.name })}</p>
          {totalNum > 0 && remainNum > 0 && (
            <>
              <div style={{ textAlign: 'center', background: 'white' }}>
                <a
                  href="#"
                  onClick={() => {
                    navigate(nextUrl);
                  }}
                >
                  <QRCode
                    style={{ margin: '10px' }}
                    value={window.location.origin  + nextUrl}
                    bgColor={'#ffffff'}
                    size={350}
                    // ecLevel="H"
                    qrStyle="dots"
                    // removeQrCodeBehindLogo={true}
                    logoImage={WaPoapLogo}
                    logoOpacity={0.3}
                    logoWidth={120}
                    // https://github.com/gcoro/react-qrcode-logo/blob/HEAD/res/eyeRadius_doc.md
                    eyeRadius={[
                      [5, 5, 5, 5], // top/left eye
                      [5, 5, 5, 5], // top/right eye
                      [5, 5, 5, 5], // bottom/left
                    ]}
                  />
                </a>
              </div>
            </>
          )}
          <div
            style={{ textAlign: 'center' }}
            className="js-clipbord"
            onClick={() => {
              addToast(`${import.meta.env.VITE_GIT_SHA} (${import.meta.env.VITE_GIT_DATE})`, {
                appearance: 'info',
                autoDismiss: true,
              });
            }}
          >
            <CustomComponent
              event_id={_eventId}
              onSet={(v: any) => {
                setRemainNum(v.remaining);
                setTotalNum(v.total);
              }}
            />
          </div>
          {totalNum > 0 && remainNum > 0 && (
            <>
              <hr />
              <label>
                {LL.SELECTED_LOCALE()}
                <select value={locale || ''} onChange={onLocaleSelected}>
                  <option value="" disabled>
                    {LL.CHOOSE_LOCALE()}
                  </option>
                  {locales.map((locale) => (
                    <option key={locale} value={locale}>
                      {locale}
                    </option>
                  ))}
                </select>
              </label>
              {searchParams.get("download") && (
                <button onClick={() => { downloadImage(); }}>
                  Download QR img!
                </button>)}
            </>
          )}
        </div>
      ) : (
        <Closed image_url={imageUrl.image_url} event_name={imageUrl.name} />
      )}
      <TemplateClaimFooter />
    </main>
  );
};

import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

// libraries
import classNames from 'classnames';

/* Helpers */
import { TokenInfo, getENSFromAddress } from '../api';
// import { reduceAddress, isValidAddress, isValidEmail } from '../lib/helpers';
import { getAddressFromLocalStorage, reduceAddress } from '../lib/helpers';

/* Assets */
import PoemLogo from '../images/POEM-logo-transparent.png';

/* Components */
import CustomComponent from './CustomCompoment';
import Waiting from '@/components/Waiting';
import { TemplateClaimFooter } from '@/incClaimPage/templateClaim/TemplateClaimFooter';
import Blockies from '@/components/Blockies';
import { addToast } from '@/components/Toast';
import { useI18nContext } from '@/i18n/i18n-react';

type AddressTokensPageState = {
  tokens: null | TokenInfo[];
  address: null | string;
  ens: null | string;
  error: boolean;
  loading: boolean;
};

export default function IncAddressTokensPage() {
  const [state, setState] = useState<AddressTokensPageState>({
    tokens: null,
    address: null,
    ens: null,
    error: false,
    loading: false,
  });

  const { tokens, error, address, ens, loading } = state;

  const [showCopied, setShowCopied] = useState<boolean>(false);
  const [addrFromHashOrAuth, setAddrFromHashOrFromAuth] = useState<string>();
  // const [addressCheck, setAddressCheck] = useState<boolean>(false);

  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const { locale, LL, setLocale } = useI18nContext();

  useEffect(() => {
    let _account = params.account;

    document.title = 'POEM NFT Collection';

    if (location.state) {
      // console.log('list/location.state', location.state);
      setAddrFromHashOrFromAuth(location.state.address);
    } else {
      getAddressFromLocalStorage(setAddrFromHashOrFromAuth);
    }

    if (_account) {
      setState((oldState) => ({ ...oldState, address: _account ? _account : null }));
      getEns(_account);
    } else {
      console.log("No addr")
    }

  }, []);

  useEffect(() => {
    if (address) {
      document.title = `POEM NFT Collection (${reduceAddress(address)})`;

    }
  }, [state]); // eslint-disable-line

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowCopied(false);
      // console.log('This will run after 1 second!');
    }, 1000);
    return () => clearTimeout(timer);
  }, [showCopied]);

  useEffect(() => {
    console.log(`addressFromHash -> ${addrFromHashOrAuth}`)
    if (!('account' in params)) {
      if (addrFromHashOrAuth) {
        navigate(`/o/scan/${addrFromHashOrAuth}`);
      } else {
        navigate(`/o/auth/0`, {
          state: {
            url: '<GoToScan>',
            message: LL.LOGIN.relogin(),
          },
        });
      }
    }
  }, [addrFromHashOrAuth]);

  const getEns = async (address: string) => {
    try {
      const ensResponse = await getENSFromAddress(address);
      setState((oldState) => ({ ...oldState, tokens, address, ens: ensResponse.valid ? ensResponse.ens : null }));
    } catch (err) {
      setState((oldState) => ({ ...oldState, error: true }));
    } finally {
      setState((oldState) => ({ ...oldState, loading: false }));
    }
  };

  // const showWallet = () => {
  //   const prepMagic = async () => {
  //     const _magic = await import('magic-sdk');

  //     const m = new _magic.Magic('pk_live_58E1B51E58F60751', {
  //       // locale: locale,
  //     } as any);

  //     m.preload().then(async () => {
  //       if (await m.user.isLoggedIn()) {
  //         const didToken = await m.user.getIdToken();

  //         console.log("didToken", didToken);
  //         m.wallet.showUI();

  //       } else {
  //         // Log in the user
  //         console.log("magic cannot be initiated");
  //       }
  //     });
  //   };
  //   prepMagic();
  //   console.log("x")
  // }

  return (
    <>
      {loading === true ? (
        <Waiting />
      ) : (
        <>
          <div className="header-events">
            <img src={PoemLogo} />
            {addrFromHashOrAuth !== undefined && (
              <Blockies
                seed={addrFromHashOrAuth}
                style={{ float: 'right' }}
                _onClick={(e) => {
                  addToast(`Blockie from your wallet address`, {
                    appearance: 'info',
                    autoDismiss: true,
                  });
                }}
              />
            )}
          </div>
          {/* <button onClick={() => {console.log("x"); showWallet()}}>x</button> */}
          <main id="site-main" role="main" className="app-content page-mycollection">
            <div className="container">
              <div className="inner">
                <div className="content-account-wrap">
                  <div className="content-account">
                    {params.account === addrFromHashOrAuth ? (
                      <h1 className="title__pagehead">MY COLLECTION</h1>
                    ) : (
                      <h1 className="title__pagehead">COLLECTION</h1>
                    )}
                    <div className="web3account">
                      <div className={classNames('clipbord-balloon', showCopied && 'is-active')}> コピーしました </div>
                      <div
                        className="js-clipbord"
                        onClick={() => {
                          if (address) {
                            navigator.clipboard.writeText(address);
                            setShowCopied(true);
                          }
                        }}
                      >
                        {ens ? (
                          <>
                            <span>{ens}</span> ({address && reduceAddress(address)})
                          </>
                        ) : (
                          <>{address && reduceAddress(address)}</>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {!error && !loading && address && (
                  <CustomComponent address={address} isOwner={params.account === addrFromHashOrAuth} />
                )}

                {error && !loading && (
                  <div className="bk-msg-error">
                    There was an error.
                    <br />
                    Check the address and try again
                  </div>
                )}

                {/* {tokens && tokens.length === 0 && (
                  <div className={classNames('event-year', 'empty-year')} style={{ marginTop: '30px' }}>
                    <img src={NoEventsImg} alt="" />
                    <p className="image-description">You don't seem to have any tokens. You're quite a couch potato!</p>
                  </div>
                )} */}
                {/* {!error && !loading && address && isValidEmail(address) && tokens && tokens.length > 0 && (
                  <div className="scan-email-badge-container">
                    <span className="scan-email-badge">
                      <b>Note:</b> These badges are not in an Ethereum wallet yet. When you're ready to claim your
                      POAPS, please click on the button below
                    </span>
                    <div className="scan-email-badge-button-container">
                      <button onClick={handleOpenRedeemModalClick} className="btn btn-primary">
                        Claim my POAPs
                      </button>
                    </div>
                  </div>
                )} */}
              </div>
              <TemplateClaimFooter />
            </div>
            {/* <ChatwootWidget name={address} /> */}
          </main>
          {/* {address && <TemplateScanFooter path={address} />} */}
        </>
      )}
    </>
  );
}

import React, { useEffect, useRef, useState } from 'react';

import browserLocalstorage from 'browser-localstorage-expire';

import { useI18nContext } from '@/i18n/i18n-react';

import { RouteComponentProps } from '../_custom';

/* Helpers */
import { HashClaim, getIncClaimHash, postIncClaimUpdate, getIncTokensFor, getTokensFor } from '../api';
import { isValidEmail } from '../lib/helpers';

/* Components*/
import {ClaimForm} from './ClaimForm';
import ClaimPending from './ClaimPending';
import ClaimFinished from './ClaimFinished';
import ClaimBumped from './ClaimBumped';

/* Constants */
import { TX_STATUS } from '../lib/constants';

/* Assets */
import { TemplateClaimFooter } from './templateClaim/TemplateClaimFooter';
import { TemplateClaimHeader } from './templateClaim/TemplateClaimHeader';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useBodyClassName } from '@/react-helpers';

import ScreenMedia from '@/components/ScreenMedia';
import Waiting from '@/components/Waiting';

export const IncClaimPage: React.FC<RouteComponentProps<{ hash: string }>> = ({ match }) => {
  const [claim, setClaim] = useState<null | HashClaim>(null);
  const [address, setAddress] = useState<string>();
  // const [magicObj, setMagicObj] = useState<any>();
  // const [dbUpdate, setDbUpdate] = useState<boolean>(false);
  const [claimError, setClaimError] = useState<boolean>(false);
  const [isVerified, setIsVerified] = useState<boolean>(false);
  const [processedNow, setProcessedNow] = useState<boolean>(false);
  const [beneficiaryHasToken, setBeneficiaryHasToken] = useState<boolean>(false);

  useBodyClassName('poap-app page-top');

  const params = useParams();
  // const localCache = browserLocalstorage();
  // const { locale, LL, setLocale } = useI18nContext();

  const calledOnce = useRef(true);
  const navigate = useNavigate();
  const location = useLocation();

  let hash = params.hash;

  let title = 'POEM Claim';

  useEffect(() => {
    if (hash)
      fetchClaim(hash)?.catch((e) => {
        setClaimError(true);
      });

    if (location.state) {
      setAddress(location.state);
    } else {
      const gotoAuth = async () => {
        navigate(`/o/auth/0`, {
          state: {
            url: window.location.pathname + window.location.search,
            message: `For claiming, you have to login`,
          },
        });
      };

      gotoAuth();
    }
  }, []); /* eslint-disable-line react-hooks/exhaustive-deps */

  useEffect(() => {
    if (claim) {
      if (claim.tx_status === 'pending') setProcessedNow(true);
      if (!isVerified) checkUserTokens();
    }
  }, [claim]); /* eslint-disable-line react-hooks/exhaustive-deps */

  const startFetching = () => {
    if (!calledOnce.current || claim === null) {
      console.log('startFetching skipped');
      return;
    }

    if (!claim.tx_status) {
      setProcessedNow(true);

      console.log('startFetching started');
      var myInterval = setInterval(() => {
        var p: Promise<void | HashClaim> | undefined = fetchClaim(claim.qr_hash, false);
        if (p !== undefined)
          p.then((claim) => {
            if (claim) {
              console.log('startFetching processed');
              setClaim(claim);
              setClaimError(false);
              if (claim.tx_status) {
                console.log('startFetching stopped');
                clearInterval(myInterval);
              }
            } else {
              console.log('cannot set claim0');
              setClaimError(true);
            }
          }).catch((e) => {
            console.log('cannot set claim');
            setClaimError(true);
          });
      }, 1000);
    } else {
      console.log('No need to setInterval');
    }
    calledOnce.current = false;
  };

  const fetchClaim = (hash: string, retPromise: boolean = true) => {
    // setIsClaimLoading(true);
    if (retPromise)
      return getIncClaimHash(hash.toLowerCase())
        .then((claim) => {
          setClaim(claim);
          setClaimError(false);
        })
        .catch((error) => {
          setClaimError(true);
        });
    // .finally(() => setIsClaimLoading(false));
    else
      return getIncClaimHash(hash.toLowerCase()).catch((error) => {
        setClaimError(true);
      });
  };

  const checkUserTokens = () => {
    if (!claim) return;

    let { user_input, beneficiary } = claim;

    let userToValidate: string | null = beneficiary;
    if (!userToValidate) {
      if (user_input && isValidEmail(user_input)) {
        userToValidate = user_input;
      }
    }

    if (!userToValidate) {
      setIsVerified(true);
      return;
    }

    getTokensFor(userToValidate, claim.event_id)
      .then((r) => {
        console.log(r);
        setBeneficiaryHasToken(true);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setIsVerified(true);
      });
  };

  const continueClaim = (claim: HashClaim) => {
    setIsVerified(false);
    setClaim(claim);
    fetchClaim(claim.qr_hash);
  };

  let body = undefined;
  if (claimError) {
    body = <div style={{ textAlign: 'center' }}>Cannot process the request.</div>;
  } else if (claim && address) {
    // if (isVerified) {
    body = <ClaimForm claim={claim} onSubmit={continueClaim} address={address} />;

    title = claim.event.name;
    if (claim.tx_status) {
      // POAP minting
      if (claim.tx_status === TX_STATUS.pending) {
        body = <ClaimFinished claim={claim} />;
      } else if (claim.tx_status === TX_STATUS.passed || beneficiaryHasToken) {
        if (processedNow) body = <ClaimFinished claim={claim} />;
        else {
          navigate(`/o/token/${claim.result?.token}`, { replace: true });
          setClaim(null);
        }
      } else if (claim.tx_status === TX_STATUS.bumped) {
        body = <ClaimBumped claim={claim} />;
      } else {
        body = <ClaimPending claim={claim} checkClaim={fetchClaim} />;
      }
    } else if (claim.claimed) {
      startFetching();
    }
  } else {
    // Waiting claim and magic
    return <Waiting />;
  }

  return (
    <>
      <div>
        <TemplateClaimHeader />
        <main id={'site-main'} role={'main'} className={`app-content`}>
          {claim && <ScreenMedia image_url={claim?.event.image_url} />}
          {body}
        </main>
        <TemplateClaimFooter />
      </div>
    </>
  );
};

import { useI18nContext } from '@/i18n/i18n-react';
import { Field, FieldProps, Form, Formik, FormikHelpers } from 'formik';
import classNames from 'classnames';

/* Helpers */
import { emailSchema } from '@/lib/schemas';
import { SubmitButton } from '@/components/SubmitButton';

import iconEnterSvg from '../images/icon_enter.svg';

type QRFormValues = {
  email: string;
};

type FormikStatusValues = {
  ok: boolean;
  msg: string;
};

export default function EmailForm({
  email,
  handle,
  formikStatus,
}: {
  email: string;
  handle: (values: QRFormValues, actions: FormikHelpers<QRFormValues>) => Promise<void>;
  formikStatus: FormikStatusValues | undefined;
}) {
  const { locale, LL, setLocale } = useI18nContext();

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={{ email: email }}
        onSubmit={handle}
        validateOnMount={email !== ''}
        validationSchema={emailSchema}
        initialStatus={formikStatus}
      >
        {({ isValid, isSubmitting, status }) => {
          // https://formik.org/docs/api/formik#props-1
          // console.log(isValid, isSubmitting, status, email, emailRegistered);
          return (
            <div>
              <Form className="form-fild">
                <p></p>
                {(() => {
                  return (
                    <>
                      <Field name="email">
                        {({ field, form }: FieldProps) => {
                          return (
                            <input
                              type="email"
                              autoComplete="off"
                              // style={{ borderColor: mainColor ?? COLORS.primaryColor }}
                              className={classNames(!!form.errors[field.name] && 'error')}
                              placeholder={LL.EMAIL_PLACE_MSG()}
                              {...field}
                            />
                          );
                        }}
                      </Field>
                      <SubmitButton
                        text={<img src={iconEnterSvg} alt="" className="icon" />}
                        isSubmitting={isSubmitting}
                        canSubmit={isValid}
                        onClick={() => {
                          // console.log('oc');
                        }}
                        style={{}}
                      />
                    </>
                  );
                })()}
                {status && <p className={status.ok ? 'bk-msg-ok' : 'bk-msg-error'}>{status.msg}</p>}
              </Form>
            </div>
          );
        }}
      </Formik>
    </>
  );
}

import { Magic } from 'magic-sdk';
import { AuthExtension } from '@magic-ext/auth';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { getEventInfo, incQrRequest, postIncEmail } from '@/api';

// import Waiting from '@/components/Waiting';
import { TemplateClaimFooter } from '@/incClaimPage/templateClaim/TemplateClaimFooter';

/* Assets */
import PoemLogo from '../images/POEM-logo-transparent.png';
import { addressCheckSummed } from '@/lib/helpers';
import PoapImage from '@/components/PoapImage';
import { eventInfoValues } from '@/lib/constants';

export const EmailLink = () => {
  const [url, setUrl] = useState<string>('');
  const [magicObj, setMagicObj] = useState<any>();
  const [cred, setCred] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [address, setAddress] = useState<string>('');
  const [hash, setHash] = useState<string>('');
  const [didToken, setDidToken] = useState<string>();
  const [systemMsg, setSystemMsg] = useState<string>('Initializing POEM NFT system');
  const [mainMsg, setMainMsg] = useState<React.JSX.Element>(<></>);
  const [counter, setCounter] = useState<number>();

  const [eventInfo, setEventInfo] = useState<eventInfoValues>({
    created: '',
    creator: '',
    description: '',
    id: -1,
    inc_event_id: -1,
    name: '',
    image_url: '',
    event_url: '',
    group_ja: '',
    start_date: '',
    image_av_color: '',
  });

  const calledOnce = useRef(true);

  const params = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (!calledOnce.current) return;
    calledOnce.current = false;

    if (magicObj) return;

    const m = new Magic('pk_live_58E1B51E58F60751', {
      extensions: [new AuthExtension()],
    });
    m.preload().then(async () => {
      console.log('magic initialized');
      m.user
        .getIdToken()
        .then((v) => {
          console.log('Magic login found');
          setDidToken(v);
        })
        .catch((e) => console.log('Magic login not found'))
        .finally(() => {
          setMagicObj(m);
        });
    });

    var _eventId = params.eventId;
    if (_eventId) {
      getEventInfo(_eventId).then((v) => {
        setEventInfo(v);
        document.title = `POEM (Email auth - ${v.name})`;
      });
    }
  }, []);

  // (0)
  useEffect(() => {
    const _email = searchParams.get('email');
    const _cred = searchParams.get('magic_credential');
    const _hash = searchParams.get('hash');
    if (_email) {
      setMainMsg(
        <>Your opinion is very important for us! As a participation certificate, A NFT is being prepared for you.</>,
      );
      console.log('email (qs) detected: ' + _email);
      // setEmail(_email);
    } else if (_cred) {
      setCounter(0.0);
      console.log('cred detected');
      setCred(_cred);
    } else if (_hash) {
      console.log('hash detected');
      setHash(_hash);
    }
  }, [searchParams]);

  useEffect(() => {
    if (eventInfo.id === -1) return;
    if (hash) return;
    if (!didToken) return;

    incQrRequest('0x', eventInfo.id.toString())
      .then((res) => {
        const _url = res.url.replace(/^.*\/claim\//, '/o/claim/');
        // console.log(_url);
        setUrl(_url);
      })
      .catch((e) => {
        setSystemMsg('Sorry, ALl POEM NFT is gone.');
      });
  }, [eventInfo, hash, didToken]);

  // (1-1/)
  useEffect(() => {
    if (!magicObj) return;
    if (!searchParams.get('email')) return;

    const _email = searchParams.get('email');

    setSystemMsg('Sending an email from POEM NFT.');

    const syncFunc = async () => {
      const sentMessage = (
        <>Email from POEM has been sent to you. Close this tab, check the Email ({_email}) and claim your NFT there!</>
      );

      const timer = setTimeout(() => {
        console.warn('No response from Magic during waiting time.');
        setSystemMsg('...');
        setMainMsg(sentMessage);
      }, 5000);

      try {
        const claimObj = await incQrRequest('0x', eventInfo.id.toString());
        const claimHash = claimObj.url.replace(/^.*\/claim\//, '');

        await magicObj.auth
          .loginWithMagicLink({
            email: _email,
            redirectURI: `${window.location.origin}/o/email/${params.eventId}?hash=${claimHash}`,
            showUI: false,
          })
          .on('email-sent', () => {
            clearTimeout(timer);
            setMainMsg(sentMessage);
            setSystemMsg('');
          });
      } catch (e: any) {
        clearTimeout(timer);
        console.error(e.message);
        setSystemMsg('Sorry, ALl POEM NFT is gone.');
      }
      // >> No effect
      // finally {
      //   const timer1 = setTimeout(() => {
      //     console.warn('Command to close');
      //     window.close();
      //   }, 15000);
      //   // setSystemMsg('This tag will be closed in 15sec.');
      // }
    };
    syncFunc();
  }, [magicObj, email]);

  // (2-1)
  useEffect(() => {
    if (!cred || !magicObj) return;

    setSystemMsg('Preparing to create POEM NFT');

    const getMetadata = async () => {
      try {
        const _didToken = await magicObj.auth.loginWithCredential(cred);
        console.log('Magic(cred)', _didToken);
        if (_didToken) setDidToken(_didToken);
      } catch {
        setSystemMsg('Processing stopped (Magic error)');
      }
    };
    getMetadata();
  }, [magicObj, cred]);

  // (2-2)
  useEffect(() => {
    if (url) return;
    if (!hash) return;
    if (!address || eventInfo.id === -1) return;

    const _url = window.location.origin + '/o/claim/' + hash;

    setUrl(_url);
    console.log('url', _url);
  }, [hash, address]);

  // (2-4-2/)
  useEffect(() => {
    const syncFunc = async () => {
      await postIncEmail({
        email: email,
        hash: url.replace('/o/claim/', ''),
      });
      navigate(url, { state: address });
    };
    if (address && url && email) {
      syncFunc();
    }
  }, [url, email, address]);

  useEffect(() => {
    if (!didToken) return;

    const syncFunc = async () => {
      try {
        const metadada = await magicObj.user.getInfo();
        // console.log('meta', metadada);
        setEmail(metadada.email);
        const addr = await addressCheckSummed(metadada.publicAddress);
        setAddress(addr);
        console.log('addr(checksummed): ' + addr);
      } catch {
        setSystemMsg('Processing stopped (Magic error metadata)');
      }
    };
    syncFunc();
  }, [didToken]);

  useEffect(() => {
    if (counter === undefined) return;

    setMainMsg(<>Finalizing the preparation. {counter.toFixed(1)}</>);
    const timer = setInterval(() => setCounter(counter + 0.1), 100);
    return () => clearInterval(timer);
  }, [counter]);

  return (
    <>
      <div className="header-events">
        <img src={PoemLogo} />
      </div>
      <main id="site-main" role="main" className="app-content">
        <div className="container">
          <div className="inner">
            <div className="content-account-wrap">
              <div className="content-account">
                <h1 className="title__pagehead">Thank you!</h1>
                <div style={{ margin: '-50px 20px 0' }}>
                  <PoapImage
                    claim={{
                      event: {
                        id: eventInfo?.id ? eventInfo?.id : -1,
                        name: eventInfo?.name ? eventInfo.name : '',
                        image_url: eventInfo?.image_url ? eventInfo.image_url : '',
                      },
                    }}
                    active={false}
                    animation={false}
                    avColor={eventInfo?.image_av_color}
                  />
                </div>
              </div>
            </div>
            <div
              style={{
                height: '100px',
                backgroundColor: 'lightgray',
                border: '1px solid gray',
                paddingInline: '10px',
                borderWidth: '1px',
              }}
            >
              {mainMsg}
            </div>
            <div
              style={{
                marginTop: '10px',
                textAlign: 'center',
                color: 'gray',
              }}
            >
              {systemMsg}
              {/* <Waiting /> */}
            </div>
          </div>
        </div>
        <TemplateClaimFooter pp={true} />
      </main>
    </>
  );
};

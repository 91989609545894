import React, { useEffect, useRef, useState } from 'react';

/* Helpers */
import {
  HashClaim,
  postIncClaimHash,
  postIncClaimHashQr,
} from '@/api';

/* Lib */
import { addressCheckSummed, reduceAddress } from '@/lib/helpers';
// import { COLORS } from '@/lib/constants';
// import { useImageSrc } from 'lib/hooks/useImageSrc';
import { useLocation, useNavigate } from 'react-router-dom';

import { useI18nContext } from '@/i18n/i18n-react';

import ReactGA4 from 'react-ga4';

import PoapImage from '../components/PoapImage';

import catchSvg from '../images/catch.svg';
import catchSvgEng from '../images/poem.eng.svg';
import Spinner from '../images/spinner.svg';

export const ClaimForm: React.FC<{
  // magic?: any;
  claim: HashClaim;
  address?: string;
  onSubmit: (claim: HashClaim) => void;
}> = ({ claim, address, onSubmit }) => {
  const [claimed, setClaimed] = useState<boolean>(false);

  const [account, setAccount] = useState<string>('');
  const [activePoap, setActivePoap] = useState<boolean>(false);

  const [errorMessage, setErrorMessage] = useState<string>('');

  const location = useLocation();
  const navigate = useNavigate();

  const calledOnce = useRef<boolean>(true);

  const { locale, LL, setLocale } = useI18nContext();

  useEffect(() => {
    // if (calledOnce.current === false) return;
    // calledOnce.current = false;

    if (account) return;

    if (!address) setErrorMessage("No account passed");
    else {
      document.title = `POEM (Address: ${address})`;
      setAccount(address);
    }
  },[]);

  useEffect(() => {
    if (account === '') return;
    if (claim.claimed) return;
    if (claimed) return;

    console.log('address changed', claimed, reduceAddress(account));

    try {
      addressCheckSummed(account)

      _postIncClaimHash(claim, account)
        .then((newClaim) => {
          onSubmit(newClaim);
        })
        .catch((error: any) => {
          setClaimed(false);
          convErrorMessage(error);
        });
    } catch (e: any) {
      console.error(`address isnt vaild: ${account}`);      
     }
    
  }, [account]);

  const _postIncClaimHash = (claim: HashClaim, account: string) => {
    setClaimed(true);

    var claimFunc = claim.qr_hash.startsWith('_') ? postIncClaimHash : postIncClaimHashQr;

    return claimFunc(
      claim.qr_hash.toLowerCase(),
      account,
      claim.secret,
      true
    );
  };

  const convErrorMessage = (v: Error) => {
    var message = v.message;
    console.log(message);
    if ((v.message as string).startsWith('Collector already claimed')) {
      // the account has issued POAP with the different hash
      setActivePoap(true);
      message = LL.ERROR.HAVEGOTTEN();
    } else if ((v.message as string).startsWith('QR Claim already claimed')) {
      message = LL.ERROR.BUMPED();
    } else if ((v.message as string).startsWith('QR Claim expired')) message = LL.ERROR.EXPIRED();
    else if ((v.message as string).indexOf('banned')) message = LL.ERROR.BANNED();
    setErrorMessage(message);
    ReactGA4.event({
      category: 'claim',
      action: 'error',
      label: message, //values.email, // optional
      value: 1,
    });
    console.log(claim.event)
    return {
      ok: false,
      msg: `バッチの配布に失敗しました: ${message}`,
    };
  };

  return (
    <div className={'container'}>
      {PoapImage({ claim, active: activePoap, animation: false,  avColor: claim.event.image_av_color})}
      <div className={'inner'}>
        {errorMessage ? (
          <>
            <div className="cancel-text01"> {errorMessage} </div>
            <p></p>
            <button
              className={'btn link-btn'}
              type="submit"
              onClick={() => {
                navigate(`/o/scan/${account}`, { replace: true });
              }}
            >
              {/* { spriteSvg } */}
              <span>{LL.LIST_BUTTON()}</span>
            </button>
          </>
        ) : (
            <>
                <img src={Spinner} alt="" className={'spinner'} />
            <h2 className={'top-catch'}>
              {locale === 'ja' ? (
                <img src={catchSvg} alt="無料NFTスタンプ - イベントに参加した証明のNFTです" />
              ) : (
                <img src={catchSvgEng} alt="Free NFT Badge = POEM" />
              )}
            </h2>
            <div className={'top-catch_text'}>
              <p>{LL.POAP_INTRO()}</p>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

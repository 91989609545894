import React, { useEffect, useRef, useState } from 'react';

import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';

import useSwipe from '@/lib/hooks/useSwipe';

import { trpc } from '../trpc';
import { useLocation, useNavigate } from 'react-router-dom';
import { SetTransfer } from './TrpcTransferSet';
// import useLongPress from '@/lib/hooks/longPress';

import Muji from '../images/muji.png';
import { addToast } from '@/components/Toast';
import ProgressiveImage from 'react-progressive-graceful-image';

export default function CustomComponent({ address, isOwner }: { address: string; isOwner: boolean }) {
  const [backgroundImage, setBackgroundImage] = useState<string>('');
  const [ownGroupId, setOwnGroupId] = useState<number>(0);
  const [groupId, setgroupId] = useState<number>(0);
  const [title, setTitle] = useState<string>();

  // const [execTransfer, setExecTransfer] = useState<boolean>(false);
  // const [tuuid, setTuuid] = useState<string>();
  const tuuid = useRef<string>();

  const [transferUuid, setTransferUuid] = useState<{
    address: string;
    token_id: number;
  }>();
  // const [execResult, setExecResult] = useState<string>();
  const [showCopied, setShowCopied] = useState<boolean>(false);

  const direction = useRef<number>(10);
  const adjacentTitles = useRef<string[]>(['', '']);

  const location = useLocation();
  // console.log("Custom/state: " + JSON.stringify(location.state));

  const navigate = useNavigate();

  const groups = trpc.api.eventGroup.useQuery({ address });
  // const mutator = trpc.api.execTransfer.useMutation();

  useEffect(() => {
    if (location.state && !('address' in location.state)) setTitle(location.state);
    else setTitle('All');
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowCopied(false);
      console.log('This will run after 1 second!');
    }, 1000);
    return () => clearTimeout(timer);
  }, [showCopied]);

  // useEffect(() => {
  //   if(transferUuid?.address && transferUuid.token_id){
  //     var uuidObj = trpc.api.createTransfer.useQuery({ address:transferUuid?.address, token_id:transferUuid?.token_id });
  //     setTuuid(uuidObj.data?.uuid)
  //   }
  // }, [transferUuid])

  // useEffect(() => {
  //   console.log('exec', execTransfer, tuuid, transferUuid);
  //   if (execTransfer && transferUuid && tuuid.current) {
  //     mutator.mutate({
  //       // token_id: transferUuid.token_id,
  //       uuid: tuuid.current,
  //       newOwner: '0x715f024db20c7ca80CC7a58C2977108aeb7c21AA',
  //     });
  //     // console.log("execResult", mutator.data)
  //     // setExecResult(mutator.data);
  //   }
  // }, [execTransfer, transferUuid]);

  // can get json objects
  // console.log(groups.data?.allGroups, groups.data?.ownGroups);

  // a hash manages groups to show for this user
  var ownedUrls: { [code: string]: number } = { '1': 1 };

  const setTitleWithDirection = (v: string) => {
    const x: string[] | undefined = groups.data?.ownGroups
      .map((v) => {
        return v.group_ja;
      })
      .filter(Boolean); // remove null
    if (x && title) {
      const diff = 20;
      x.unshift('All');
      const oldIdx = x.indexOf(title);
      const newIdx = x.indexOf(v);
      console.log(oldIdx, newIdx, title, v, JSON.stringify(x));
      direction.current = oldIdx - newIdx > 0 ? diff : -1 * diff;

      var adjacents = ['', ''];
      if (newIdx === 0) adjacents[1] = x[1];
      else if (newIdx === x.length - 1) adjacents[0] = x[x.length - 2];
      else adjacents = [x[newIdx - 1], x[newIdx + 1]];
      adjacentTitles.current = adjacents;
      console.log('setTitleWithDirection', adjacentTitles.current);
    }
    setTitle(v);
  };

  const swipeHandlers = useSwipe({
    onSwipedLeft: () => {
      // console.log('left: ' + adjacentTitles.current);
      if (adjacentTitles.current[1]) setTitleWithDirection(adjacentTitles.current[1]);
    },
    onSwipedRight: () => {
      // console.log('right: ' + adjacentTitles.current);
      if (adjacentTitles.current[0]) setTitleWithDirection(adjacentTitles.current[0]);
    },
  });

  const timerRef = useRef<NodeJS.Timeout | number>();
  const isLongPress = useRef<boolean>(false);

  const longPushStart = (address: string, token_id: number) => {
    if (isOwner && token_id < 1000000) {
      isLongPress.current = false;
      timerRef.current = setTimeout(() => {
        setTransferUuid({ address, token_id });
        console.log('longpress', address, token_id);
        isLongPress.current = true;
      }, 500);
    } else {
      console.log('longPushStart', `Skipped to create transfer for ${token_id}`);
    }
  };
  const longPushCancel = () => {
    console.log('longPushCancel');
    if (timerRef.current) clearTimeout(timerRef.current);
    // timerRef.current = undefined;
  };

  return (
    <React.StrictMode>
      <nav className="event-navi js-tabnavi">
        <ul>
          <li
            key="All"
            className={classNames('js-tabnavi-all', title === 'All' && 'is-current')}
            // style={title === 'All' ? {} : { /* textDecoration: 'none' */ }}
            onClick={() => {
              if ('All' !== title) setTitleWithDirection('All');
              // console.log('All');
            }}
          >
            <a href="#" key="All">
              All
            </a>
          </li>
          {groups.data?.ownGroups.map((t, index) => {
            return (
              <li
                key={t.group_ja as React.Key}
                className={classNames('js-tabnavi-all', title === t.group_ja && 'is-current')}
                // style={title === t.group_ja ? {} : { transform: 'translateX(100%)' }}
                onClick={() => {
                  if (t.group_ja !== title) setTitleWithDirection(t.group_ja);
                  // console.log('set ' + t.group_ja);
                }}
              >
                <a href="#" key={t.group_ja as React.Key}>
                  {t.group_ja}
                </a>
              </li>
            );
          })}
        </ul>
      </nav>
      <div className="mycollection">
        {groups.data?.ownGroups[0] === undefined ? (
          <div className="is-active js-tabcontent event-year">
            {groups.status === 'success' ? (
              <div className="events-logos">No data found</div>
            ) : (
              <div className="events-logos">Loading NFTs...</div>
            )}
          </div>
        ) : (
          <div
            className="is-active js-tabcontent event-year"
            style={
              backgroundImage
                ? {
                    backgroundImage: backgroundImage,
                    height: '800px',
                  }
                : {}
            }
          >
            {(() => {
              // set initial adjacentTitles here bc cannot catch on useEffect(..,[])
              if (!adjacentTitles.current[0] && !adjacentTitles.current[1] && groups.data)
                adjacentTitles.current = ['', groups.data?.ownGroups[0].group_ja];
              return true;
            })()}
            <AnimatePresence mode="wait">
              <motion.div
                {...swipeHandlers}
                // onTouchMove={()=>{}} // this disables swipe
                key={title as React.Key}
                initial={{ x: direction.current * -1, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                exit={{ x: 0, opacity: 0 }}
                transition={{ duration: 0.2 }}
                onAnimationComplete={() => {
                  if (title === 'MUJI') setBackgroundImage(`url(${Muji})`);
                  else setBackgroundImage('');
                }}
              >
                <h2>{title}</h2>
                {!groups.data && (
                  <>
                    <div className="pager">
                      <span className="is-active"></span>
                    </div>
                  </>
                )}
                <div key={title} className="events-logos">
                  {title === 'All' ? (
                    <>
                      {groups.data?.ownGroups.map((t, index0) => {
                        return t.image_urls.map((tt, index) => {
                          // const { action, handlers } = useLongPress();
                          // console.log(t.tokens[index], action)
                          return (
                            <motion.a
                              key={t.ids[index]}
                              initial={false}
                              //animate={{ backgroundColor: isOpen ? "#FF0088" : "#0055FF" }}
                              whileTap={{
                                scale: 0.95,
                              }}
                              className="event-circle is-active"
                              onClick={() => {
                                if (isLongPress.current) {
                                  console.log('Is long press - skip click event.');
                                  return;
                                }
                                navigate('/o/token/' + t.tokens[index], { state: title });
                              }}
                              onTouchStart={() => {
                                longPushStart(address, parseInt(t.tokens[index]));
                              }}
                              onMouseDown={() => {
                                longPushStart(address, parseInt(t.tokens[index]));
                              }}
                              onTouchEnd={longPushCancel}
                              onMouseUp={longPushCancel}
                              onMouseOut={() => {
                                // console.log('onMouseOut');
                                timerRef.current = undefined;
                              }}
                            >
                              <span>
                                {parseInt(t.ids[index]) < 1000000 ? (
                                  <ProgressiveImage
                                    key={'pimg_' + t.tokens[index]}
                                    src={tt}
                                    placeholder={`https://storage.googleapis.com/${import.meta.env.VITE_GOOGLE_STORAGE_BUCKET}/s_${t.ids[index]}.webp`}
                                  >
                                    {(src) => {
                                      return <img key={'img_' + t.tokens[index]} src={src} alt={t.names[index]} />;
                                    }}
                                  </ProgressiveImage>
                                ) : (
                                  <img key={'img_' + t.tokens[index]} src={tt} alt={t.names[index]} />
                                )}
                              </span>
                            </motion.a>
                          );
                        });
                      })}
                    </>
                  ) : (
                    <>
                      {groups.data?.ownGroups[ownGroupId].image_urls.map((t, index) => {
                        ownedUrls[t] = 1;
                        return (
                          <motion.a
                            key={groups.data?.ownGroups[ownGroupId].ids[index]}
                            initial={false}
                            //animate={{ backgroundColor: isOpen ? "#FF0088" : "#0055FF" }}
                            whileTap={{
                              scale: 0.95,
                            }}
                            className="event-circle is-active"
                            onClick={() =>
                              navigate('/o/token/' + groups.data?.ownGroups[ownGroupId].tokens[index], {
                                state: title,
                              })
                            }
                          >
                            <span>
                              <img
                                key={groups.data?.ownGroups[ownGroupId].ids[index]}
                                src={t}
                                alt={groups.data?.ownGroups[ownGroupId].names[index]}
                              />
                            </span>
                          </motion.a>
                        );
                      })}
                      {/* {JSON.stringify(groups.data?.allGroups[0])} */}
                      {(() => {
                        groups.data?.ownGroups.map((t, index) => {
                          // console.log('title_0', t.group_ja);
                          if (t.group_ja === title) {
                            if (ownGroupId !== index) setOwnGroupId(index);
                          }
                        });
                        groups.data?.allGroups.map((t, index) => {
                          // console.log('allGroups', t.group_ja);
                          if (t.group_ja === title) {
                            if (groupId !== index) setgroupId(index);
                          }
                        });
                        return <></>;
                      })()}
                      {groups.data?.allGroups[groupId].image_urls.map((t, index) => {
                        if (ownedUrls[t]) return <div key={groups.data?.allGroups[groupId].ids[index]}></div>;
                        else if (groups.data?.allGroups[groupId].group_ja !== null) {
                          return (
                            <div
                              key={groups.data?.allGroups[groupId].ids[index]}
                              className="event-circle is-blank"
                              onClick={(e) => {
                                // console.log("click")
                                if (isLongPress.current) {
                                  console.log('Is long press - skip click event.');
                                  return;
                                }
                                addToast(`${groups.data?.allGroups[groupId].names[index]}`, {
                                  appearance: 'info',
                                  autoDismiss: true,
                                });
                              }}
                            >
                              <span>
                                <img
                                  key={groups.data?.allGroups[groupId].ids[index]}
                                  src={t}
                                  alt={groups.data?.allGroups[groupId].names[index]}
                                />
                              </span>
                            </div>
                          );
                        }
                      })}
                    </>
                  )}
                </div>
              </motion.div>
            </AnimatePresence>
          </div>
        )}
      </div>
      {transferUuid && (
        <>
          <div
            className={'web3account'}
            style={{
              position: 'relative',
              color: '#FF5722',
            }}
          >
            <div className={classNames('clipbord-balloon', showCopied && 'is-active')}>コピーしました </div>
            <div
              className={'js-clipbord'}
              onClick={() => {
                navigator.clipboard.writeText(`${window.location.origin}/o/transfer/${tuuid.current}`);
                setShowCopied(true);
              }}
            >
              <SetTransfer
                address={transferUuid.address}
                token_id={transferUuid.token_id}
                onSet={(v: string) => {
                  tuuid.current = v;
                }}
              />
            </div>
          </div>
        </>
      )}
      {/* {!mutator.isLoading && JSON.stringify(mutator.data)}
      {mutator.error && <p>Something went wrong! {mutator.error.message}</p>} */}
    </React.StrictMode>
  );
}

import React, { useEffect, useRef, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import { useBodyClassName } from '../react-helpers';

import { getEventInfo, postIncStriped } from '@/api';

import PoapImage from '@/components/PoapImage';
import PoapInfo from '@/components/PoapInfo';

import CustomComponent from '../incQrPage/CustomCompoment';
import { eventInfoValues } from '@/lib/constants';

export const IncEventPage = () => {
  const [eventInfo, setEventInfo] = useState<null | eventInfoValues>({
    created: '',
    creator: '',
    description: '',
    id: -1,
    inc_event_id: -1,
    name: '',
    image_url: '',
    group_ja: '',
    event_url: '',
    start_date: '',
    image_av_color: '',
  });

  const [donutEnabled, setDonutEnabled] = useState<boolean>(false);
  const [remainingPercent, setRemainingPercent] = useState<number>(0);

  const calledOnce = useRef<boolean>(true);

  useBodyClassName('poap-app');
  const params = useParams();
  const [searchParams] = useSearchParams();

  // console.log('searchParams', searchParams.keys(), searchParams.get('donut'), remainingPercent);
  useEffect(() => {
    if (calledOnce.current === false) return;
    calledOnce.current = false;

    document.title = 'POEM (Event Info)';

    var _eventId = params.eventId;
    if (_eventId) {
      getEventInfo(_eventId).then((v) => {
        setEventInfo(v);
        document.title = `POEM (Event Info - ${v.name})`;
      });
    }
    // setInterval(() => { 
    //   setRemainingPercent(remainingPercent => remainingPercent + 1);
    //   console.log("incremented: " + remainingPercent)
    // }, 3000);
  });

  useEffect(() => {
    if(searchParams.get('donut')){
      setDonutEnabled(true);
    }
    // Stripe
    const session_id = searchParams.get('session_id');
    if (params.eventId && session_id) {
      postIncStriped(params.eventId, session_id)
    }
  }, [searchParams]);

  return (
    <main id="site-main" role="main" className="app-content">
      {/* <Blockies seed={ "0x49AaA285ce05cC0B860FD29d8E65A3027bDc6f33" } /> */}
      <div className="inner">
        {donutEnabled ? <PoapImage
          claim={{
            event: {
              id: eventInfo?.id ? eventInfo.id : -1,
              name: eventInfo?.name ? eventInfo.name : '',
              image_url: eventInfo?.image_url ? eventInfo.image_url : '',
            },
          }}
          active={false}
          animation={false}
          avColor={eventInfo?.image_av_color}
          remainingPercent={remainingPercent}
        /> :
          <PoapImage
            claim={{
              event: {
                id: eventInfo?.id ? eventInfo?.id : -1,
                name: eventInfo?.name ? eventInfo.name : '',
                image_url: eventInfo?.image_url ? eventInfo.image_url : '',
              },
            }}
            active={false}
            animation={false}
            avColor={eventInfo?.image_av_color}
          />}
        {eventInfo && (
          <>
            <PoapInfo eventInfo={eventInfo} tokenId={undefined} />
            <CustomComponent
              event_id={`${eventInfo.id}`}
              onSet={(v: any) => {
                const p = 100 * v.remaining / v.total;
                setRemainingPercent(p);
                // document.title = `POEM (Event Info - ${eventInfo.name} - ${p})`;
                console.log(v.remaining, v.total, p);
              }}
            />
          </>
        )}
      </div>
    </main>
  );
};

import React from 'react';

/* Lib */

/* Types */
/* Assets */
import HachiAtPng from '../../images/logo_hachi_at.png';

// https://qiita.com/ham0215/items/ce2be94286d726f7edc5
export const TemplateClaimFooter = ({ pp = false }: { pp?: boolean }) => {
  return (
    <div style={{ textAlign: 'center', fontSize: 10, paddingTop: '50px' }}>
      {pp && (
        <>
          We use your information according to <a href="https://link.poap.dev/zEkK7z"> our Privacy Policy</a>. You can
          update your preferences at any time.
        </>
      )}
      <div className="footer_linkbnr" style={{ paddingTop: '5px' }}>
        <a href="https://link.poap.dev/QvMnNI">
          <img src={HachiAtPng} alt="Hachi at UG" />
        </a>
      </div>
    </div>
  );
};

import { useI18nContext } from '@/i18n/i18n-react';

import { trpc } from '../trpc';

import chainLogo from '../images/klaytn.jpg';
import { useEffect, useState } from 'react';
// import chainLogo from '../images/mumbai.svg';

type AppProps = {
  eventInfo: {
    id: number;
    description: string;
    event_url: string;
    created_date?: string | undefined;
    start_date?: string | undefined;
    creator_url?: string;
  };
  tokenId?: number;
  supply?: { order: number; total: number } | undefined;
};

export default function PoapInfo({ eventInfo, tokenId, supply }: AppProps) {
  const [result, setResult] = useState<number[]>();
  const [counter, setCounter] = useState<number>(0);

  const { locale, LL, setLocale } = useI18nContext();

  var counts = trpc.api.urlCount.useQuery({ event_id: `${eventInfo.id}`, refreshCount: 0 });

  if (counts.data) {
    if (result) {
      if (result[0] !== counts.data.total - counts.data.remaining)
        setResult([counts.data.total - counts.data.remaining, counts.data.total]);
    } else setResult([counts.data.total - counts.data.remaining, counts.data.total]);
  }

  useEffect(() => {
    const timer = setInterval(() => setCounter(counter + 1), 100);
    return () => clearInterval(timer);
  }, [counter]);

  return (
    <>
      <div className={'inner'}>
        <div className={'content'}>
          <div className={'lead-event'} dangerouslySetInnerHTML={{ __html: eventInfo.description }} />
          <div className={'issue-detail'}>
            <div className={'issue-detail_box'}>
              <div className={'issue-detail_num'}>
                {tokenId != undefined &&
                  (tokenId >= 0 ? (
                    <>
                      #{tokenId}
                      <a
                        href={
                          //`https://testnets.opensea.io/assets/baobab/0x3F378a264564e94EE4f8225aE0172ac62Ed17B59/${tokenId}`
                          // `https://rarible.com/token/polygon/${import.meta.env.VITE_MINT_DELEGATE_CONTRACT}:${tokenId}?tab=overview`
                          `${import.meta.env.VITE_SCAN_URL}/${import.meta.env.VITE_MINT_DELEGATE_CONTRACT}/${tokenId}`
                        }
                        target={'_blank'}
                      >
                        <img src={chainLogo} style={{ marginLeft: '5px', width: '15px', position: 'relative', top: '1px' }} />
                      </a>
                    </>
                  ) : (
                    <small>
                      {LL.CONFIRMING()} {(counter / 10).toFixed(1)}
                    </small>
                  ))}
              </div>
              <div className={'issue-detail_count'}>
                {supply ? (
                  <>
                    <span>{supply?.order}</span> / {supply?.total}
                  </>
                ) : result ? (
                  <>
                    <span>{result[0]}</span> / {result[1]}
                  </>
                ) : (
                  <span>...</span>
                )}
              </div>
            </div>
            {tokenId && <time className={'issue-detail_date'}>{eventInfo.start_date}</time>}
          </div>
          <div className={'created-link'}>
            {(() => {
              if (eventInfo.creator_url)
                return (
                  <a href={eventInfo.creator_url} target="_blank">
                    {LL.MORE_INFO.TO_OTHER_PAGE()}
                  </a>
                );
              else if (eventInfo.event_url)
                return (
                  <>{LL.MORE_INFO.TO_OTHER_PAGE()}</>
                );
              else return <>{LL.MORE_INFO.ERROR()}</>;
            })()}
          </div>
        </div>
      </div>
    </>
  );
}

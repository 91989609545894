import { useEffect, useState } from 'react';
import { trpc } from '../trpc';

import { useI18nContext } from '@/i18n/i18n-react';
import Waiting from '@/components/Waiting';

type AppProps = {
  event_id: string | undefined;
  onSet: Function;
};

export default function CustomComponent({ event_id, onSet }: AppProps) {
  const [result, setResult] = useState<number[]>();
  const [timeoutCount, setTimeoutCount] = useState<number>(0);

  const { locale, LL, setLocale } = useI18nContext();

  if (!event_id) return <>Error</>;

  const hello = trpc.api.urlCount.useQuery({ event_id, refreshCount: 0 });

  useEffect(() => {

    const timeout = setTimeout(() => {
      // console.log("cleared");
      setTimeoutCount(timeoutCount + 1);
      if (hello.data) {
        console.log(`removed ${timeoutCount}`);
        hello.remove();
      } else {
        console.log("remove skipped");
      }
    }, 5000);

    return () => {
      clearTimeout(timeout);
    };
  }, [timeoutCount]);

  useEffect(() => {
    if (!result) return;
    // Wrap parent's function with useEffect to prevent the render confliction.
    // https://stackoverflow.com/a/70589600/1684957
    console.log('onSet', result);
    onSet({ remaining: result[0], total: result[1] });
  }, [result]);

  if (hello.data) {
    // console.log(timeoutCount, hello.data, result)
    if (result){
      if (result[0] !== hello.data.remaining)
        setResult([hello.data.remaining, hello.data.total]);
    } else setResult([hello.data.remaining, hello.data.total]);
  }

  return result ? (
    <>
      {LL.COUNTER ({ total: result[1], remaining: result[0] })}
    </>
  ) : (
      <><Waiting/>{ result }</>
  );
}

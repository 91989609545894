import React, { Suspense, lazy, useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { motion } from 'framer-motion';

import TypesafeI18n from './i18n/i18n-react';
import { detectLocale } from './i18n/i18n-util';
import { loadLocaleAsync } from './i18n/i18n-util.async';
import { localStorageDetector, navigatorDetector } from 'typesafe-i18n/detectors';

import { ROUTES } from './lib/constants';

import { Outlet, useLocation } from 'react-router-dom';
import Redirect from '@/Redirect';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
// import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import { httpBatchLink } from '@trpc/client';
import { trpc } from './trpc';
import { API_BASE } from './api';

import { IncLogoutPage } from './incLogoutPage';
import { IncScanHome } from './incScanPage';
import { MagicAuth } from './incLoginPage';
import { IncEventPage } from './incEventPage';
import { IncTransferPage } from './incTransferPage';
// import { IncSecretPage } from './incSecretPage';
import { IncClaimPage } from './incClaimPage';
import { IncQrPage } from '@/incQrPage';
import { IncUrlPage } from '@/incUrlPage';
import { EmailLink } from './incLoginPage/email';
import IncCameraPage from './incCameraPage';
// import { IncUploadPage } from '@/incUploadPage';

const IncSecretPage = lazy(() => import('./incSecretPage'));
const IncUploadPage = lazy(() => import('@/incUploadPage'));

// const detectedLocale = detectLocale(localStorageDetector)
const detectedLocale = detectLocale(navigatorDetector);

// https://stackoverflow.com/a/71812613/1684957
//@ts-ignore
const PageLayout = ({ children }) => children;

const pageVariants = {
  initial: {
    opacity: 0,
  },
  in: {
    opacity: 1,
  },
  out: {
    opacity: 0,
  },
};

const pageTransition = {
  type: 'tween',
  ease: 'linear',
  duration: 0.5,
};

const AnimationLayout = () => {
  const { pathname } = useLocation();
  return (
    <PageLayout>
      <motion.div key={pathname} initial="initial" animate="in" variants={pageVariants} transition={pageTransition}>
        <Outlet />
      </motion.div>
    </PageLayout>
  );
};

const App: React.FC<{}> = (_) => {
  const [queryClient] = useState(() => new QueryClient());
  const [trpcClient] = useState(() =>
    trpc.createClient({
      links: [
        httpBatchLink({
          url: `${API_BASE}/trpc`,
          // optional
          // headers() {
          //   return {
          //     authorization: getAuthCookie(),
          //   };
          // },
        }),
      ],
    }),
  );

  const [wasLoaded, setWasLoaded] = useState(false);

  useEffect(() => {
    loadLocaleAsync(detectedLocale).then(() => setWasLoaded(true));
  }, []);

  if (!wasLoaded) return null;

  return (
    <trpc.Provider client={trpcClient} queryClient={queryClient}>
      <QueryClientProvider client={queryClient}>
        {/* <ReactQueryDevtools initialIsOpen={true} /> */}
        <TypesafeI18n locale={detectedLocale}>
          <Router>
            <Suspense fallback={<div>...loading</div>}>
              <Routes>
                <Route element={<AnimationLayout />}>
                  {/* <Route path={ROUTES.callback} element={<Callback />} /> */}
                  <Route path={ROUTES.incTransferHome} element={<IncTransferPage />} />
                  <Route path={ROUTES.incEventHome} element={<IncEventPage />} />
                  <Route path={ROUTES.incSecretPage} element={<IncSecretPage />} />
                  <Route path={ROUTES.incCameraPage} element={<IncCameraPage />} />
                  <Route path={ROUTES.incEmailPage} element={<EmailLink />} />
                  <Route path={ROUTES.incMagicAuth} element={<MagicAuth />} />
                  <Route path={ROUTES.incLoginPage} element={<MagicAuth />} />
                  <Route path={ROUTES.incClaimPageHash} element={<IncClaimPage />} />
                  {/* <Route path={ROUTES.incClaimPage} element={<IncClaimPage />} />{' '} */}
                  <Route path={ROUTES.incQrHome} element={<IncQrPage />} />
                  <Route path={ROUTES.incUrlHome} element={<IncUrlPage />} />
                  <Route path={ROUTES.incScanHome} element={<IncScanHome />} />
                  <Route path={ROUTES.incUploadHome} element={<IncUploadPage />} />
                  <Route path={ROUTES.incLogoutPage} element={<IncLogoutPage />} />
                  <Route path={ROUTES.ohome} element={<Redirect url={'https://hachiat.de/'} />} />
                  <Route path={ROUTES.home} element={<Redirect url={'https://hachiat.de/'} />} />
                </Route>
              </Routes>
            </Suspense>
          </Router>
        </TypesafeI18n>
      </QueryClientProvider>
    </trpc.Provider>
  );
};

export default App;

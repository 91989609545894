import React, { useCallback, useEffect, useRef, useState } from 'react';
import Webcam from 'react-webcam';
import { Navigate, useNavigate, useParams, useSearchParams } from 'react-router-dom';

import browserLocalstorage from 'browser-localstorage-expire';

/* Assets */
import PoemLogo from '../images/POEM-logo-transparent.png';

const FACING_MODE_USER = 'user';
const FACING_MODE_ENVIRONMENT = 'environment';

const videoConstraints = {
  width: 440,
  height: 440,
  facingMode: FACING_MODE_ENVIRONMENT,
};

export const IncCameraPage = () => {
  const [isCaptureEnable, setCaptureEnable] = useState<boolean>(true);
  const [facingMode, setFacingMode] = React.useState(FACING_MODE_USER);
  const [url, setUrl] = useState<string | null>(null);

  const webcamRef = useRef<Webcam>(null);

  const navigate = useNavigate();
  const localCache = browserLocalstorage();

  const capture = useCallback(() => {
    const imageSrc = webcamRef.current?.getScreenshot();
    if (imageSrc) {
      setUrl(imageSrc);
      localCache.setItem('photo', imageSrc, 5); // 5min?
      navigate(`/o/upload`, {});
    }
  }, [webcamRef]);

  const handleClick = useCallback(() => {
    setFacingMode((prevState) => (prevState === FACING_MODE_ENVIRONMENT ? FACING_MODE_USER : FACING_MODE_ENVIRONMENT));
  }, []);

  const calledOnce = useRef(true);

  useEffect(() => {
    if (!calledOnce.current) return;
    calledOnce.current = false;

    document.title = `POEM (CAMERA)`;
  }, []);

  return (
    <>
      <div className="header-events">
        <img src={PoemLogo} />
      </div>
      <main id="site-main" role="main" className="app-content page-mycollection">
        <div className="container">
          <div className="inner">
            <div className="content-account-wrap">
              <div className="content-account">
                <h1 className="title__pagehead">POEM CAMERA</h1>

                {/* {isCaptureEnable || <button onClick={() => setCaptureEnable(true)}>start</button>} */}
                {isCaptureEnable && (
                  <>
                    {/* <div>
            <button onClick={() => setCaptureEnable(false)}>end </button>
          </div> */}
                    <div style={{ width: '440px' }}>
                      <Webcam
                        audio={false}
                        // width={440}
                        // height={500}
                        minScreenshotHeight={500}
                        minScreenshotWidth={500}
                        ref={webcamRef}
                        screenshotFormat="image/png"
                        videoConstraints={{
                          ...videoConstraints,
                          facingMode,
                        }}
                      />
                    </div>
                  </>
                )}
                {/* {url && (
            <>
              <div>
                <button
                  onClick={() => {
                    setUrl(null);
                  }}
                >
                  delete
                </button>
              </div>
              <div>
                <img src={url} alt="Screenshot" />
              </div>
            </>
          )} */}
              </div>
            </div>
            <div
              style={{
                marginTop: '10px',
                position: 'relative',
              }}
            >
              <button
                onClick={handleClick}
                style={{
                  position: 'absolute',
                  left: 0,
                  color: '#fff',
                  backgroundColor: 'gray',
                  justifyContent: 'center',
                  width: '100px',
                  height: '25px',
                  borderRadius: '9px',
                  marginTop: '5px',
                  marginRight: '5px',
                }}
              >
                Switch camera
              </button>
              <button
                onClick={capture}
                style={{
                  position: 'absolute',
                  right: 0,
                  color: '#fff',
                  backgroundColor: 'gray',
                  justifyContent: 'center',
                  width: '100px',
                  height: '25px',
                  borderRadius: '9px',
                  marginTop: '5px',
                }}
              >
                capture
              </button>
            </div>
          </div>
        </div>
      </main>
      <div style={{ textAlign: 'center', marginTop: '20px', color: 'lightgray' }}>
        {import.meta.env.VITE_GIT_DATE}, {import.meta.env.MODE}
      </div>
    </>
  );
};

export default IncCameraPage;

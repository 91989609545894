import React from 'react';
import { useI18nContext } from '@/i18n/i18n-react';

import { Loading } from '@/components/Loading';

const Waiting = () => {

  const { locale, LL, setLocale } = useI18nContext();

  return (
    <div className="header-events">
      <main id="site-main" role="main" className="app-content">
        <div className="container">
          <div className="content-event years">
            <Loading />
            <div style={{ textAlign: 'center' }}>{LL.WAITING()}</div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Waiting;

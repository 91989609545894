import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { RouteComponentProps } from '../_custom';

import { useBodyClassName } from '../react-helpers';

import { getEventInfo, incQrRequest } from '@/api';

import Waiting from '@/components/Waiting';
import Closed from '@/components/Closed';

// import { TemplateScanFooter } from './templateScan/TemplateScanFooter';
// import { TemplateScanHeader } from './templateScan/TemplateScanHeader';

export const IncUrlPage = () => {
  const [incUrlAvailable, setIncUrlAvailable] = useState<string>('');
  const [error, setError] = useState<boolean>(false);
  const [imageUrl, setImageUrl] = useState<string>('');
  const [eventName, setEventName] = useState<string>('');
  const navigate = useNavigate();

  const calledOnce = useRef<boolean>(true);

  const getUrlForQr = async (eventId: string) => {
    incQrRequest('0x', eventId)
      .then((res) => {
        // Original claiming url is provided, so change it to our version
        const url = res.url.replace(/^.*\/claim\//, '/o/claim/');
        console.log('Claim url being used', url);
        setIncUrlAvailable('Success');
        navigate(url);
      })
      .catch((e) => {
        const er: Error = e as Error;
        setError(true);
      });
  };

  useBodyClassName('poap-app');
  const params = useParams();

  useEffect(() => {
    if (calledOnce.current === false) return;
    calledOnce.current = false;

    document.title = 'POEM (URL selection)';

    var _eventId = params.eventId;
    var eventUrl = undefined;

    if (_eventId) {
      getEventInfo(_eventId).then((v) => {
        setEventName(v.name);
        setImageUrl(v.image_url);
      });
      // eventUrl = 'https://poap.gallery/event/' + _eventId;
      getUrlForQr(_eventId);
    } else console.error('Cannot get claim url');
  });

  return (
    <>
      {error ? (
        <Closed image_url={imageUrl} event_name={eventName} />
      ) : (
        <div className="landing">
          {incUrlAvailable !== '' && <p>{incUrlAvailable}</p>}
          {incUrlAvailable === '' && <Waiting />}
        </div>
      )}
    </>
  );
};

import { useI18nContext } from '@/i18n/i18n-react';

import ScreenMedia from './ScreenMedia';
import { useEffect, useState } from 'react';
import classNames from 'classnames';

type AppProps = {
  event_name: string;
  image_url: string;
};

export default function Closed({ image_url, event_name }: AppProps) {
  const [showMessage, setShowMessage] = useState<boolean>(false);
  const [transparent, setTransparent] = useState<boolean>(false);

  const { locale, LL, setLocale } = useI18nContext();

  useEffect(() => {
    setShowMessage(true);
    setTransparent(true);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowMessage(false);
    }, 6000);
    return () => clearTimeout(timer);
  }, [transparent]);

  return (
    <>
      <div className="container">
        <ScreenMedia image_url={image_url} />
        <div id="js-bg" className="content-event single is-active">
          <div className="all-logos">
            <div className="event-year">
              <div id="js-logos" className="events-logos is-active">
                <div className="event-circle">
                  <span>
                    <img src={image_url} alt="" />
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div id="js-msg" className={classNames('message-event', showMessage && 'is-active')}>
            {event_name ? LL.CLOSE_INTRO({ event_name }) : "The badge requested not found"}
          </div>
        </div>
        <div className="inner">
          <div className="content">
            <div className="lead-event alignC">
              {LL.CLOSE_MSG[0]()}<br/>{LL.CLOSE_MSG[1]()}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

import { useI18nContext } from '@/i18n/i18n-react';

import { trpc } from '../trpc';
import { reduceAddress } from '@/lib/helpers';

type AppProps = {
  tokenId: number;
};

export default function PoapHistory({ tokenId }: AppProps) {
  const { locale, LL, setLocale } = useI18nContext();

  const historyAry = trpc.api.getTokenHistory.useQuery({ tokenId });

  return (
    <table>
      {historyAry ? (
        <>
          {historyAry.data?.map((t, index) => {
            return (
              <tr>
                <td style={{ borderStyle: "soild", borderWidth: "1px" }} >{index}</td>
                <td style={{ borderStyle: "soild", borderWidth: "1px"}}>{t.transferred.substring(0, 16).replace("T"," ")}</td>
                <td style={{  borderStyle: "soild", borderWidth: "1px"}}>{reduceAddress(t.current_owner)} -&gt; {reduceAddress(t.new_owner)}</td>
              </tr>
            );
          })}
        </>
      ) : (
        <>No history found</>
      )}
    </table>
  );
}

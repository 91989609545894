import React, { useEffect } from 'react';

const Redirect : React.FC<{
   url: string;
 }> = ({url}) => {
   useEffect(() => {
      window.location.href = url;
   }, [url]);

   return <h5>Redirecting...</h5>;
};

export default Redirect;

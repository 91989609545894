import React, { useEffect, useState } from 'react';

import { motion } from 'framer-motion';
import { useI18nContext } from '@/i18n/i18n-react';

// routing
import { useParams, useNavigate, useLocation } from 'react-router-dom';

// Helpers
import { getIncTokenInfo, TokenInfo } from '../api';

// utils
import { useBodyClassName } from '../react-helpers';
import ScreenMedia from '@/components/ScreenMedia';
import Waiting from '@/components/Waiting';
import PoapImage from '@/components/PoapImage';

import PoapInfo from '@/components/PoapInfo';
import PoapHistory from '@/components/PoapHistory';
import { TemplateClaimFooter } from '@/incClaimPage/templateClaim/TemplateClaimFooter';
import { getAddressFromLocalStorage, reduceAddress } from '@/lib/helpers';

export default function IncTokenDetailPage() {
  const [token, setToken] = useState<null | TokenInfo>(null);
  const [nullbody, setNullbody] = useState(<Waiting />);
  const [addressFromHash, setAddressFromHash] = useState<string>('');

  // const [transferUuid, setTransferUuid] = useState<string>();
  // const [eventInfo, setEventInfo] = useState<null | {
  //   created: string;
  //   creator: string;
  //   description: string;
  //   id: number;
  //   inc_event_id: number;
  //   name: string;
  //   image_url: string;
  //   image_av_color: string;
  //   creator_url: string;
  // }>({
  //   created: '',
  //   creator: '',
  //   description: '',
  //   id: -1,
  //   inc_event_id: -1,
  //   name: '',
  //   image_url: '',
  //   image_av_color: '',
  //   creator_url: ''
  // });

  const { locale, LL, setLocale } = useI18nContext();

  const params = useParams();
  const navigate = useNavigate();

  // const location = useLocation();
  // console.log("Detail/state: " + JSON.stringify(location.state));

  let tokenId = params.tokenId;

  useBodyClassName('poap-app event-page');

  useEffect(() => {
    document.title = 'POEM NFT';

    getAddressFromLocalStorage(setAddressFromHash);
  }, []);

  useEffect(() => {
    const fn = async () => {
      if (tokenId !== undefined) {
        const token = await getIncTokenInfo(parseInt(tokenId));
        if ('owner' in token) {
          document.title = `POEM NFT (${token.event.name as string} ${token.supply.order}/${token.supply.total})`;
          setToken(token);
        } else {
          setNullbody(<div>No data found</div>);
        }
      } else {
        console.log('tokenId is undefined');
      }
    };
    fn();
  }, [tokenId]);

  if (token === null) {
    return (
      <>
        <main id={'site-main'} role={'main'} className={`app-content`}>
          <div className={'container'}>{nullbody}</div>
        </main>
      </>
    );
  }

  return (
    <>
      <main id={'site-main'} role={'main'} className={`app-content`}>
        <div className={'container'}>
          <ScreenMedia image_url={token.event.image_url} />

          <PoapImage claim={token} active={true} animation={false} avColor={token.event.image_av_color} />
          {token.owner !== addressFromHash && (
            <div className={'web3account'}>Owned by {reduceAddress(token.owner)}</div>
          )}
          <PoapInfo eventInfo={token.event} tokenId={parseInt(token.tokenId)} supply={token?.supply} />
          <PoapHistory tokenId={parseInt(token.tokenId)} />
          <motion.button
            initial={false}
            //animate={{ backgroundColor: isOpen ? "#FF0088" : "#0055FF" }}
            whileTap={{
              scale: 0.95,
            }}
            className={'btn link-btn'}
            type="submit"
            onClick={() => {
              if (addressFromHash && addressFromHash === token.owner) navigate(`/o/scan/${addressFromHash}`);
              else navigate(`/o/scan/${token.owner}`);
            }}
          >
            {(addressFromHash && addressFromHash === token.owner) ? <span>{LL.LIST_BUTTON()}</span> : <span>{LL.LIST_BUTTON_NOADDR()}</span>}
          </motion.button>
          <TemplateClaimFooter />
        </div>
      </main>
    </>
  );
}

import { HashClaim } from '@/api';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import ProgressiveImage from 'react-progressive-graceful-image';

export default function PoapImage({
  children,
  claim,
  active = false,
  animation = false,
  avColor = '#ffffff',
  remainingPercent = 0,
}: {
  children?: React.ReactNode;
  claim: HashClaim | { event: { id: number; image_url: string; name: string } };
  active?: boolean;
  animation?: boolean;
  avColor?: string | undefined;
  remainingPercent?: number;
}) {
  const [titleColor, setTitleColor] = useState<string>('rbg(255,255,255)');

  useEffect(() => {
    // the title color should be blask for a shared badge
    if (active && avColor) {
      const invColor = invertColor(avColor, true);
      setTitleColor(invColor);
    }
    console.log("GCS: " + import.meta.env.VITE_GOOGLE_STORAGE_BUCKET)
  });

  useEffect(() => {
    console.log('remainingPercent', remainingPercent);
    if (remainingPercent) {
      const DonutSlice = [
        {
          id: 1,
          percent: 100 - remainingPercent,
          color: 'DarkOrchid',
        },
        // {
        //   id: 2,
        //   percent: remainingPercent,
        //   color: 'White',
        // },
      ];

      const circlecontainer = document.getElementById('circlecontainer');
      if (circlecontainer) {
        circlecontainer.innerHTML = DonutSlice.map((item, i, arr) => {
          let offset = 2 * i + arr.filter((item, j) => j < i).reduce((total, item) => total + item.percent, 0);
          return `<circle data-id="${item.id}" stroke="${item.color}"
                  cx="20" cy="20" r="19" class="slice"
                  style="
                    stroke-linecap: round;
                    stroke-width: 2;
                    fill: none;
                    cursor: pointer;"
                  opacity="1" 
                  fill-opacity="0"
                  pathLength="${100 + 2 * arr.length}"
                  stroke-dasharray="${item.percent} 100"
                  stroke-dashoffset="-${offset}" />`;
        }).join('');
      }
    }
  }, [remainingPercent]);

  function invertColor(hex: string, bw: boolean) {
    function padZero(str: string, len?: number) {
      len = len || 2;
      var zeros = new Array(len).join('0');
      return (zeros + str).slice(-len);
    }

    if (hex.indexOf('#') === 0) {
      hex = hex.slice(1);
    }
    // convert 3-digit hex to 6-digits.
    if (hex.length === 3) {
      hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    if (hex.length !== 6) {
      throw new Error('Invalid HEX color.');
    }
    var r = parseInt(hex.slice(0, 2), 16),
      g = parseInt(hex.slice(2, 4), 16),
      b = parseInt(hex.slice(4, 6), 16);

    if (bw) {
      // https://stackoverflow.com/a/3943023/112731
      return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? '#000000' : '#ffffff';
    }
    // invert color components
    const sr = (255 - r).toString(16);
    const sg = (255 - g).toString(16);
    const sb = (255 - b).toString(16);
    // pad each with zeros and return
    // console.log('av calced');
    return '#' + padZero(sr) + padZero(sg) + padZero(sb);
  }

  const ProgressImg = (
    <ProgressiveImage
      src={claim.event.image_url}
      placeholder={`https://storage.googleapis.com/${import.meta.env.VITE_GOOGLE_STORAGE_BUCKET}/s_${
        claim.event.id
      }.webp`}
    >
      {(src) => {
        console.log('event.id', claim.event.id);
        return (
          <img
            src={src}
            alt={claim.event.name}
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
            }}
          />
        );
      }}
    </ProgressiveImage>
  );

  return (
    <>
      <div className={classNames('content-event', active ? 'single is-active' : 'single-top')}>
        <div className={'all-logos'}>
          <div className={'event-year'}>
            <div className={classNames('events-logos', active && 'is-active')}>
              {animation ? (
                // https://www.framer.com/motion/transition/#targetandtransition
                <motion.div
                  className={classNames('event-circle')}
                  initial={{ opacity: 0, scale: 0.8 }}
                  animate={{ opacity: 1, scale: 1 }}
                  transition={{
                    duration: 0.5,
                    delay: 0.2,
                    ease: [0, 0.71, 0.2, 1.01],
                  }}
                  whileTap={{ scale: 1.4 }}
                >
                  <span>
                    <img src={claim.event.image_url} />
                  </span>
                </motion.div>
              ) : (
                <motion.div id={'js-logos'} className={classNames('event-circle')} whileTap={{ scale: 1.4 }}>
                  {remainingPercent && (
                    <svg xmlns="http//www.w3.org/2000/svg" viewBox="0 0 40 40" id="svg">
                      <g id="circlecontainer" transform="rotate(-90 20 20)"></g>
                    </svg>
                  )}
                  {claim.event.id !== -1 ? (
                    ProgressImg
                  ) : (
                    <>
                      <img
                        src={claim.event.image_url}
                        style={{
                          position: 'absolute',
                          top: 0,
                          left: 0,
                        }}
                      />
                    </>
                  )}
                </motion.div>
              )}
              <div className={'event-name'} style={{ color: titleColor }}>
                {claim.event.name}
              </div>
            </div>
          </div>
        </div>
        <>{children}</>
      </div>
    </>
  );
}

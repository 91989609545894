import React from 'react';

type screenMediaProps = {
  image_url?: string;
};

const ScreenMedia: React.FC<screenMediaProps> = ({ image_url }) => {
  if (!image_url) return <></>;
  else
    return (
      <style media={'screen'}>
        {'\
  .content-event.single:before {\
    background-image: url("' +
          image_url +
          '"\
  );\
  '}
      </style>
    );
};

export default ScreenMedia;

import React, { useEffect } from 'react';
import { useI18nContext } from '@/i18n/i18n-react';

/* Helpers */
import { HashClaim } from '../api';
// import { blockscoutLinks } from '../lib/constants';

/* Components */

// import { LinkButton } from '../components/LinkButton';
/* Assets */
import Spinner from '../images/spinner.svg';
import PoapImage from '../components/PoapImage';
// import ClaimFooterMessage from './ClaimFooterMessage';

/*
 * @dev: Component to show user that transactions is being mined
 * */
const ClaimPending: React.FC<{ claim: HashClaim; checkClaim: (hash: string) => void }> = ({ claim, checkClaim }) => {
  const { locale, LL, setLocale } = useI18nContext();

  useEffect(() => {
    document.title = 'POEM (Claiming)';

    const interval = setInterval(() => {
      checkClaim(claim.qr_hash);
    }, 1000);
    return () => clearInterval(interval);
  }, []); /* eslint-disable-line react-hooks/exhaustive-deps */

  return (
    <div className={'container'}>
      <div className={'inner'}>
        {PoapImage({ claim })}
      </div>
      <div className={'inner'}>
        <img src={Spinner} alt="" className={'spinner'} />
        <h2 className={'progress-title'}>
          <>
          {LL.ISSUING()}
          <span className={'progress-step'}>･</span>
          <span className={'progress-step'}>･</span>
          <span className={'progress-step'}>･</span>
          </>
        </h2>
        <div className={'progress-text'}>
          <p>{LL.ISSUING_INTRO()}</p>
        </div>
        <div className="pager">
          <span className="is-active"></span>
          <span className="is-active"></span>
          <span className="is-active"></span>
        </div>
      </div>
      {/* <div className={'text-info'}>Pen...</div> */}
    </div>
  );
};

export default ClaimPending;

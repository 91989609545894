import * as yup from 'yup';
// import { ethers, getDefaultProvider } from 'ethers';

import { Magic } from 'magic-sdk';
import browserLocalstorage from 'browser-localstorage-expire';
import { getBeneficiary } from '@/api';
// import { useI18nContext } from '@/i18n/i18n-react';
import { Locales } from '@/i18n/i18n-types';

// import { resolveENS } from '../api';

const localCache = browserLocalstorage();

const MAGIC_AUTH_KEY = 'pk_live_58E1B51E58F60751';

function getAddressFromLocalStorage(setAccount: Function) {

  const prevTokenId: string | null = localCache.getItem('incHash');
  if (prevTokenId) {
    return getBeneficiary(prevTokenId).then((prevTokenInfo) => {
      const address = prevTokenInfo.beneficiary;
      if (address) {
        setAccount(address);
        console.log('address found from hash: ' + reduceAddress(address));
      } else console.error("No address found with the Hash on localCache");
      return address;
    });
  } else {
    setAccount('');
    console.log("No hash found on localCache")
    return new Promise(function (resolve, reject) {
      reject('No hash found on localCache');
    });
  }
}

function clearLocalStorage() {
  localCache.setItem('incHash', '', 1);
}

async function clearMagicAuthLogin() {
  const m = new Magic(MAGIC_AUTH_KEY);
  const pe = await m.user.logout();
  console.log(`clearMagicAuthLogin called: ${pe}`);
}
        
// async function isValidAddressOrENS(str: string): Promise<boolean> {
//   if (isValidAddress(str)) {
//     return true;
//   } else {
//     try {
//       let response = await resolveENS(str);
//       if (response) return true;
//     } catch (error) {
//       return false;
//     }
//   }
//   return false;
// }

// async function resolveName(name: string): Promise<string> {
//   const mainnetProvider = getDefaultProvider('homestead');
//   const resolvedAddress = await mainnetProvider.resolveName(name);
//   return resolvedAddress || '';
// }

const isValidEmail = (email: string) => {
  if (!email) return false; // yup return true if email = ''
  return yup.string().email().isValidSync(email);
};

const ethEnabled = async (setMsg: Function, setMagic: Function, locale?: Locales, magicObj?: Magic) => {
  // https://docs.ethers.org/v6/api/address/
  const ethers = await import('ethers');

  if (locale === undefined)
    locale = "en";

  try {
    if (window.ethereum) {
      // Check MetaMask is connected to this site.
      //
      // > window.ethereum.isConnected() will force to open Metamask..
      // >  https://stackoverflow.com/a/73445373/1684957
      // > NEW?
      // >  https://stackoverflow.com/a/71265025
      // 
      var accounts = await window.ethereum.request({method: 'eth_accounts'});  
      if (accounts.length > 0) {
        setMsg(`logged in (MetaMask): ${accounts}`);
        // return;
      } else {
        setMsg('Not logged in (MetaMask)');
      }

      window.web3 = new ethers.BrowserProvider(window.ethereum);
      // if (accounts.length === 1) setAccount(accounts[0]);

      return true;
    } else {
      setMsg('Loading Magic to check...');
      //
      // https://thirdweb.com/klaytn-testnet-baobab
      //
      // socat TCP4-LISTEN:8651,fork TCP4:api.baobab.klaytn.net:8651
      //
      // Magic.auth(Polygon/Mumbai) is used if magicObj isnt provided
      var m = new Magic(MAGIC_AUTH_KEY);
      const isLoggedIn = await m.user.isLoggedIn()
      setMsg('Check info with magic.auth');
      // check Magic has been connected to this site
      if (!isLoggedIn) {
        setMsg('Not logged in (Magic.auth) and checking connect...');
        try {
          console.log('Check info with magic.connect');
          m = magicObj ? magicObj : new Magic('pk_live_D6FDA1F6CE4F9D28', {
            locale: locale,
            // network: { rpcUrl: import.meta.env.VITE_RPC_URL, chainId: import.meta.env.VITE_RPC_CHAIN_ID },
          } as any);
          await m.user.getInfo();
          setMsg('Logged in (Magic.connect)');
        } catch (e) {
          setMsg('Not logged in (Magic.auth or Magic.connect)');
          return false;
        }
      }
      setMsg('Logged in (Magic.auth)');

      setMagic(m);
      window.web3 = new ethers.BrowserProvider(m.rpcProvider);
      return true;
    }
  } catch (e) {
    return false;
  }
};

export const addressCheckSummed = async (address: string) => {
  const ethers = await import('ethers');
  return ethers.getAddress(address);
}

const ethGetAddress = async (setAddress: Function, setPhasePerFour: Function) => {
  const ethers = await import('ethers');
  try {
    setPhasePerFour(3);
    await window.web3.send('eth_accounts', []);
    const signer = await window.web3.getSigner();
    setPhasePerFour(4);
    // https://docs.ethers.org/v6/api/providers/#BrowserProvider-getSigner
    var address = await signer.getAddress();
    // Convert to checksummed
    // https://docs.ethers.org/v6/api/address/#getAddress
    address = ethers.getAddress(address);

    // setSigner(signer);
    setAddress(address);
  } catch (e) {
    console.error(e);
    throw e;
  }
};

const reduceAddress = (address: string) => {
  if (address.length < 10) return address;
  return address.slice(0, 6) + '\u2026' + address.slice(-4);
};

const generateSecretCode = () =>
  Math.floor(Math.random() * 999999)
    .toString()
    .padStart(6, '0');

const getBase64 = (img: File | Blob, callback: (outputFile: string | undefined) => void) => {
  const reader = new FileReader();

  reader.addEventListener('load', () => {
    if (typeof reader.result === 'string') return reader.result;
  });
  reader.readAsDataURL(img);
};

export {
  getBase64,
  // isValidAddress,
  // isValidAddressOrENS,
  ethEnabled,
  ethGetAddress,
  reduceAddress,
  generateSecretCode,
  isValidEmail,
  getAddressFromLocalStorage,
  clearLocalStorage,
  clearMagicAuthLogin
};

import React, { CSSProperties } from 'react';
import classNames from 'classnames';

import { motion } from 'framer-motion';

export const SubmitButton: React.FC<{
  text: string | JSX.Element | undefined;
  isSubmitting: boolean;
  canSubmit: boolean;
  style?: CSSProperties;
  className?: string;
  onClick?: () => void;
}> = ({ isSubmitting, canSubmit, text, style, className, onClick = () => null }) => (
  <motion.button
    whileHover={{ scale: 1.1 }}
    whileTap={{ scale: 0.9 }}
    className={classNames('btn', isSubmitting && 'loading', className && `${className}`)}
    type="submit"
    style={style}
    disabled={isSubmitting || !canSubmit}
    onClick={onClick}
  >
    {isSubmitting ? (
      <div className={'progress-title'} style={{paddingTop: '0px'}}>
        <span className={'progress-step'}>･</span>
        <span className={'progress-step'}>･</span>
        <span className={'progress-step'}>･</span>
      </div>
    ) : (
      text
    )}
  </motion.button>
);

const ROLES = {
  administrator: 'administrator',
  eventHost: 'event_host',
};

export type eventInfoValues = {
  created: string;
  creator: string;
  description: string;
  id: number;
  inc_event_id: number;
  name: string;
  image_url: string;
  event_url: string;
  group_ja: string;
  start_date: string;
  image_av_color: string;
  creator_url?: string;
};

const STYLES = {
  // Adding #80 to RGB color for 50% opacity
  boxShadow: (color: string) => `0px 10px 30px -5px ${color}80`,
};

const LABELS = {
  issueBadges: {
    roles: [ROLES.administrator],
    title: 'Issue Badges',
  },
  inbox: {
    roles: [ROLES.administrator],
    title: 'Inbox',
  },
  otherTasks: {
    roles: [ROLES.administrator],
    title: 'Other Tasks',
  },
  quickLinks: {
    roles: [ROLES.eventHost],
    title: 'Quick Links',
  },
  menu: {
    roles: [ROLES.eventHost],
    title: 'Menu',
  },
};

const ROUTES = {
  home: '/',
  ohome: '/o',
  incTransferHome: '/o/transfer/:uuid',
  incMagicAuth: '/o/auth/:eventId',
  incEventHome: '/o/event/:eventId',
  incSecretPage: '/o/secret/:eventId',
  incCameraPage: '/o/camera/:eventId',
  incEmailPage: '/o/email/:eventId',
  incUploadHome: '/o/upload',
  incQrHome: '/o/qr/:eventId',
  incUrlHome: '/o/url/:eventId',
  incScanHome: '/o/*',
  // incListPage: '/o/scan/:account',
  // incTokenPage: '/o/token/:tokenId',
  redeem: '/redeem/:uid',
  token: '/token/:tokenId',
  callback: '/callback',
  codeClaimWeb3PageHash: '/claim/:hash/:method',
  codeClaimPageHash: '/claim/:hash', // OK
  codeClaimPage: '/claim',
  incClaimPageHash: '/o/claim/:hash', // OK
  // incClaimPage: '/o/claim',
  incLoginPage: '/o/login',
  incLogoutPage: '/o/logout',
  admin: '/admin/*',
  adminLogin: {
    path: '/admin/login',
    title: 'Login',
  },
  issueForEvent: {
    path: 'issue-for-event',
    roles: [ROLES.administrator],
    title: 'Many Users',
  },
  template: {
    path: 'template',
    roles: [ROLES.eventHost],
    title: 'Manage Templates',
  },
  templateForm: {
    path: '/admin/template/form/:id?',
    roles: [ROLES.eventHost],
  },
  issueForUser: {
    path: 'issue-for-user',
    roles: [ROLES.administrator],
    title: 'Many Events',
  },
  events: {
    path: 'events/*',
    roles: [ROLES.administrator, ROLES.eventHost],
    // title: 'Manage Events',
  },
  eventsListFull: {
    path: 'events/list',
    roles: [ROLES.administrator, ROLES.eventHost],
    title: 'Manage Events',
  },
  eventsList: {
    path: 'list',
    roles: [ROLES.administrator, ROLES.eventHost],
    title: 'Manage Events',
  },
  eventsNew: {
    path: 'new',
    roles: [ROLES.administrator, ROLES.eventHost],
  },
  event: {
    path: ':eventId',
    roles: [ROLES.administrator, ROLES.eventHost],
  },
  minters: {
    path: 'minters',
    roles: [ROLES.administrator],
  },
  burn: {
    path: '/admin/burn',
    roles: [ROLES.administrator],
    title: 'Burn Tokens',
  },
  burnToken: {
    path: '/admin/burn/:tokenId',
    roles: [ROLES.administrator],
    title: 'Burn Token',
  },
  addressManagement: {
    path: 'address-management',
    roles: [ROLES.administrator],
    title: 'Manage Addresses',
  },
  transactions: {
    path: 'transactions',
    roles: [ROLES.administrator],
    title: 'Transactions',
  },
  inbox: {
    path: 'inbox',
    roles: [ROLES.administrator],
    title: 'Send Notification',
  },
  inboxList: {
    path: 'inbox-list',
    roles: [ROLES.administrator],
    title: 'Notifications List',
  },
  qr: {
    path: 'qr',
    roles: [ROLES.administrator, ROLES.eventHost],
    title: 'Manage QR Codes',
  },
};

const IMAGE_SUPPORTED_FORMATS = ['image/png'];

const TX_STATUS = {
  failed: 'failed',
  passed: 'passed',
  pending: 'pending',
  bumped: 'bumped',
};

const LAYERS = {
  layer1: 'Layer1',
  layer2: 'Layer2',
};

const layerOneNetwork = import.meta.env.VITE_ETH_NETWORK === 'mainnet' ? '' : `${import.meta.env.VITE_ETH_NETWORK}.`;
// const layerTwoNetwork = process.env.REACT_APP_L2_ETH_NETWORK;

const etherscanLinks = {
  tx: (hash: string): string => `https://${layerOneNetwork}etherscan.io/tx/${hash}`,
  address: (address: string): string => `https://${layerOneNetwork}etherscan.io/address/${address}`,
};

const blockscoutLinks = {
  tx: (hash: string): string => `https://blockscout.com/xdai/mainnet/tx/${hash}`,
  address: (address: string): string => `https://blockscout.com/xdai/mainnet/address/${address}`,
};

export {
  ROLES,
  ROUTES,
  TX_STATUS,
  LAYERS,
  etherscanLinks,
  blockscoutLinks,
  LABELS,
  IMAGE_SUPPORTED_FORMATS,
  STYLES,
};

import React, { FC } from 'react';

// assets
// import WaPoapLogo from '../../images/WAPOAP.svg';
// import Star from 'images/white-star.svg';
// import HeaderShadow from 'images/header-shadow-desktop-white.svg';

// lib
// import { COLORS, STYLES } from 'lib/constants';

// types

export const TemplateClaimHeader = () => {

  return (
    <>
      <div className="header-events">
        {/* <img src={WaPoapLogo} /> */}
      </div>
    </>
  );
};

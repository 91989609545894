import { useEffect, useState } from 'react';

import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { LoginWithMagicLinkConfiguration, Magic } from 'magic-sdk';
import { AuthExtension } from '@magic-ext/auth';
import { FormikHelpers } from 'formik';

import { getEventInfo, incQrRequest, magicAuthLogin, postIncEmail } from '@/api';
import { useBodyClassName } from '@/react-helpers';

import PoapImage from '@/components/PoapImage';
import Waiting from '@/components/Waiting';

import EmailForm from '../components/EmailForm';

import { eventInfoValues } from '@/lib/constants';
import { addressCheckSummed, ethEnabled, ethGetAddress, getAddressFromLocalStorage } from '@/lib/helpers';

import { TemplateClaimHeader } from '../incClaimPage/templateClaim/TemplateClaimHeader';
import { TemplateClaimFooter } from '../incClaimPage/templateClaim/TemplateClaimFooter';
import Closed from '@/components/Closed';
import { useI18nContext } from '@/i18n/i18n-react';
import { motion } from 'framer-motion';

type QRFormValues = {
  email: string;
};

type FormikStatusValues = {
  ok: boolean;
  msg: string;
};

export function MagicAuth() {
  //   const { locale, LL, setLocale } = useI18nContext();
  const [url, setUrl] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const [emailSent, setEmailSent] = useState<string>('');
  const [eventInfo, setEventInfo] = useState<eventInfoValues>();
  const [magicObj, setMagicObj] = useState<any>();
  const [didChecked, setDidChecked] = useState<boolean>(false);
  const [formikStatus, setFormikStatus] = useState<FormikStatusValues>();
  const [addressFromHash, setAddressFromHash] = useState<string>();

  const [error, setError] = useState<boolean>(false);

  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const { locale, LL, setLocale } = useI18nContext();

  useBodyClassName('poap-app');

  useEffect(() => {
    if (params.eventId) {
      let _eventId = params.eventId;

      getAddressFromLocalStorage(setAddressFromHash);

      getEventInfo(_eventId).then((v) => {
        setEventInfo(v);
      });

      if (location.state && 'url' in location.state) {
        setUrl(location.state.url);
        setMessage(location.state.message);
      } else
        incQrRequest('0x', _eventId)
          .then((res) => {
            // Original claiming url is provided, so change it to our version
            const url = res.url.replace(/^.*\/claim\//, '/o/claim/');
            console.log('Claim url being used', url);
            setUrl(url);
            setMessage(LL.LOGIN['default']);
          })
          .catch((e) => {
            const er: Error = e as Error;
            setError(true);
            console.log('incQrRequest err');
          });
    } else console.warn('No eventId');

    const request_start0_at = performance.now();

    const m = new Magic('pk_live_58E1B51E58F60751', {
      extensions: [new AuthExtension()],
    });
    m.preload().then(async () => {
      const request_end0_at = performance.now();
      const request_duration = request_end0_at - request_start0_at;
      console.log('magic init duration', request_duration);
      setMagicObj(m);
    });
  }, []);

  const addrRegister = (_addr: string, email?: string) => {
    if (!_addr) return;

    if (url === '<GoToScan>')
      navigate('/o/scan/' + _addr, {
        state: {
          address: _addr,
        },
      });
    else if (url === '/o/upload') {
      navigate(url, {
        state: {
          email,
          address: _addr,
        },
      });
    } else navigate(url, { state: _addr });
  };

  useEffect(() => {
    if (!magicObj || !url) return;

    magicObj.user
      .getInfo()
      .then(async (userMetadata: any) => {
        console.log('idToken', userMetadata, url);
        const addr = await addressCheckSummed(userMetadata.publicAddress);
        setEmailSent(userMetadata.email);
        setDidChecked(true);
        addrRegister(addr, userMetadata.email);
      })
      .catch(() => {
        console.log('Cannot recover did');
        setDidChecked(true);
      });
  }, [magicObj]);

  useEffect(() => {
    if (!url) return;
    if (url.startsWith('<')) return; // <GoToScan>

    const func = async () => {
      try {
        await getAddressFromLocalStorage(addrRegister);
      } catch (e) {
        console.log(e);
      }
    };

    func();
  }, [url]);

  useEffect(() => {
    if (!eventInfo) return;

    document.title = `POEM Login (${eventInfo.name})`;
  }, [eventInfo]);

  const loginWithMagic = async (values: QRFormValues) => {
    // Trigger Magic link to be sent to user
    const request_start_at = performance.now();

    const didToken = await magicObj.auth.loginWithEmailOTP({
      email: values.email,
      showUI: true,
    } as LoginWithMagicLinkConfiguration);

    const request_end_at = performance.now();
    const request_duration = request_end_at - request_start_at;
    console.log('did duration', request_duration);

    // Validate didToken with server
    if (didToken) {
      const res = await magicAuthLogin(didToken, values.email);

      const request_end2_at = performance.now();
      const request_duration2 = request_end2_at - request_end_at;

      if (res.status === 200) {
        // Set the UserContext to the now logged in user
        const userMetadata = await magicObj.user.getInfo();
        // userMetadata = {issuer: 'did:ethr:0x4DB9164e65de3F6776b82a3216dCAcA663B2c36b', publicAddress: '0x4db9164e65de3f6776b82a3216dcaca663b2c36b', email: 'tf0054@gmail.com', isMfaEnabled: false, phoneNumber: null}
        if (userMetadata.issuer) {
          setEmailSent(userMetadata.email);
          const _addr = userMetadata.issuer?.substring(9);

          addrRegister(_addr, userMetadata.email);
        } else {
          console.error('Unknown json returned by our server');
        }
      } else {
        console.error(res);
      }
    } else console.error('Cannot get didToken');
  };

  const magicOrMetamask = async (values: QRFormValues, actions: FormikHelpers<QRFormValues>) => {
    actions.setSubmitting(true);
    if (url.indexOf('/o/claim/') > -1) {
      await postIncEmail({
        email: values.email,
        hash: url.replace('/o/claim/', ''),
      });
    }
    if (window.ethereum) {
      console.log('web3 browser detected');
      const setWeb3OnWindow = await ethEnabled(console.log, console.log, locale);
      if (setWeb3OnWindow) {
        await ethGetAddress((_addr: string) => {
          addrRegister(_addr);
        }, console.log).catch(async (e) => {
          // Calceled on Metamask?
          console.warn(e);
          await loginWithMagic(values);
        });
      } else {
        console.warn('Cannot get address from web3 browser');
        await loginWithMagic(values);
      }
    } else {
      await loginWithMagic(values);
    }
  };

  const setButtonToList = (
    <>
      {' '}
      {addressFromHash && (
        <motion.button
          initial={false}
          //animate={{ backgroundColor: isOpen ? "#FF0088" : "#0055FF" }}
          whileTap={{
            scale: 0.95,
          }}
          className={'btn link-btn'}
          type="submit"
          onClick={() => {
            navigate(`/o/scan/${addressFromHash}`);
          }}
        >
          <span>{LL.LIST_BUTTON()}</span>
        </motion.button>
      )}
    </>
  );

  return (
    <>
      {error ? (
        <main id="site-main" role="main" className="app-content">
          <Closed
            image_url={eventInfo?.image_url ? eventInfo?.image_url : ''}
            event_name={eventInfo?.name ? eventInfo?.name : ''}
          />
          {setButtonToList}
          <TemplateClaimFooter />
        </main>
      ) : didChecked && magicObj && url && eventInfo ? (
        <>
          <TemplateClaimHeader />
          <main id={'site-main'} role={'main'} className={`app-content`}>
            {eventInfo && (
              <PoapImage
                claim={{ event: { id: eventInfo.id, image_url: eventInfo.image_url, name: eventInfo.name } }}
              />
            )}
            <div style={{ textAlign: 'center' }}>
              <h2>{message}</h2>
              <br />
              {!emailSent && <EmailForm email={emailSent} handle={magicOrMetamask} formikStatus={formikStatus} />}
              {setButtonToList}
            </div>
          </main>
          <TemplateClaimFooter pp={true} />
        </>
      ) : (
        <Waiting />
      )}
    </>
  );
}

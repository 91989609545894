import {TouchEvent, useRef} from "react";

interface SwipeInput {
    onSwipedLeft: () => void
    onSwipedRight: () => void
}

interface SwipeOutput {
    onTouchStart: (e: TouchEvent) => void
    onTouchMove: (e: TouchEvent) => void
    onTouchEnd: () => void
}

export default (input: SwipeInput): SwipeOutput => {
    // const [touchStart, setTouchStart] = useState(0);
    // const [touchEnd, setTouchEnd] = useState(0);
    const touchStart = useRef(0);
    const touchEnd = useRef(0);
    const touchMoved = useRef(false);

    const minSwipeDistance = 50;

    const onTouchStart = (e: TouchEvent) => {
        touchEnd.current = 0; // otherwise the swipe is fired even with usual touch events
        touchStart.current = e.targetTouches[0].clientX;
    }

    const onTouchMove = (e: TouchEvent) => {
        touchEnd.current = e.targetTouches[0].clientX;
        touchMoved.current = true;
    }

    const onTouchEnd = () => {
        if (!touchStart || !touchEnd || !touchMoved.current) return;
        const distance = touchStart.current - touchEnd.current;
        const isLeftSwipe = distance > minSwipeDistance;
        const isRightSwipe = distance < -1 * minSwipeDistance;

        if (isLeftSwipe) {
            input.onSwipedLeft();
        }
        if (isRightSwipe) {
            input.onSwipedRight();
        }
        touchMoved.current = false;
    }

    return {
        onTouchStart,
        onTouchMove,
        onTouchEnd
    }
}

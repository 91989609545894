import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

type toastOpt = {
  appearance: string;
  autoDismiss?: boolean;
};

export const addToast = (msg: string, extOpt: toastOpt) => {
  const opt = {
    autoClose: 2000,
    closeOnClick: true,
    position: toast.POSITION.TOP_CENTER,
  };

  if (extOpt.appearance === 'error') toast.error(msg, opt);
  else if (extOpt.appearance === 'success') toast.success(msg, opt);
  else if (extOpt.appearance === 'warning') toast.warning(msg, opt);
  else if (extOpt.appearance === 'info') toast.info(msg, { ...opt, hideProgressBar: true});
  else toast.info(msg, opt);
};

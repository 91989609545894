import React from 'react';
import { Route, Routes } from 'react-router';
import { Outlet, useLocation, useNavigationType } from 'react-router-dom';

import IncTokenDetailPage from './TokenDetailPage';
import IncAddressTokensPage from './AddressTokensPage';

// https://stackoverflow.com/a/73920933/1684957
export const DebugLayout = () => {
  const location = useLocation();
  const navigationType = useNavigationType(); // "POP" | "PUSH" | "REPLACE"

  React.useEffect(() => {
    console.log('The current URL is', { ...location });
    console.log('The last navigation action was', navigationType);
  }, [location, navigationType]);

  return <Outlet />;
};

export const IncScanHome = () => {

  return (
    <Routes>
      <Route element={<DebugLayout />}>
        <Route path={'scan'} element={<IncAddressTokensPage />} />
        <Route path={'scan/:account'} element={<IncAddressTokensPage />} />
        <Route path={'token/:tokenId'} element={<IncTokenDetailPage />} />
      </Route>
    </Routes>
  );
};

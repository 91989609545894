import React, { useEffect, useRef, useState } from 'react';

import { addToast } from '@/components/Toast';
import browserLocalstorage from 'browser-localstorage-expire';

import { RouteComponentProps } from '../_custom';

import { useBodyClassName } from '../react-helpers';

// import LoadingSpinner from '../images/loading.svg';
import { ethers } from 'ethers';
// import { Magic } from 'magic-sdk';
// import { ConnectExtension } from '@magic-ext/connect';
import { SubmitButton } from '@/components/SubmitButton';
import { useNavigate } from 'react-router-dom';
import { clearLocalStorage, clearMagicAuthLogin, ethEnabled, ethGetAddress, getAddressFromLocalStorage } from '@/lib/helpers';
import { useI18nContext } from '@/i18n/i18n-react';

export const IncLogoutPage: React.FC<
  RouteComponentProps<{
    eventUrl: string;
  }>
> = (_) => {
  const [address, setAddress] = useState<string>('');
  const [addressFromHash, setAddressFromHash] = useState<string>('');
  const [message, setMessage] = useState<string>();
  const [magicObj, setMagicObj] = useState<any>();

  const { locale, LL, setLocale } = useI18nContext();
  //
  const contractABI = {
    _format: 'hh-sol-artifact-1',
    contractName: 'PoemNFT',
    sourceName: 'contracts/PoemNFT.sol',
    abi: [
      {
        anonymous: false,
        inputs: [
          {
            indexed: false,
            internalType: 'address',
            name: 'previousAdmin',
            type: 'address',
          },
          {
            indexed: false,
            internalType: 'address',
            name: 'newAdmin',
            type: 'address',
          },
        ],
        name: 'AdminChanged',
        type: 'event',
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: 'address',
            name: 'owner',
            type: 'address',
          },
          {
            indexed: true,
            internalType: 'address',
            name: 'approved',
            type: 'address',
          },
          {
            indexed: true,
            internalType: 'uint256',
            name: 'tokenId',
            type: 'uint256',
          },
        ],
        name: 'Approval',
        type: 'event',
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: 'address',
            name: 'owner',
            type: 'address',
          },
          {
            indexed: true,
            internalType: 'address',
            name: 'operator',
            type: 'address',
          },
          {
            indexed: false,
            internalType: 'bool',
            name: 'approved',
            type: 'bool',
          },
        ],
        name: 'ApprovalForAll',
        type: 'event',
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: 'address',
            name: 'beacon',
            type: 'address',
          },
        ],
        name: 'BeaconUpgraded',
        type: 'event',
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: false,
            internalType: 'uint8',
            name: 'version',
            type: 'uint8',
          },
        ],
        name: 'Initialized',
        type: 'event',
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: false,
            internalType: 'uint256',
            name: '_tokenId',
            type: 'uint256',
          },
        ],
        name: 'Mint',
        type: 'event',
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: 'address',
            name: 'previousOwner',
            type: 'address',
          },
          {
            indexed: true,
            internalType: 'address',
            name: 'newOwner',
            type: 'address',
          },
        ],
        name: 'OwnershipTransferred',
        type: 'event',
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: 'address',
            name: 'from',
            type: 'address',
          },
          {
            indexed: true,
            internalType: 'address',
            name: 'to',
            type: 'address',
          },
          {
            indexed: true,
            internalType: 'uint256',
            name: 'tokenId',
            type: 'uint256',
          },
        ],
        name: 'Transfer',
        type: 'event',
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: 'address',
            name: 'implementation',
            type: 'address',
          },
        ],
        name: 'Upgraded',
        type: 'event',
      },
      {
        inputs: [
          {
            internalType: 'address',
            name: 'to',
            type: 'address',
          },
          {
            internalType: 'uint256',
            name: 'tokenId',
            type: 'uint256',
          },
        ],
        name: 'approve',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        inputs: [
          {
            internalType: 'address',
            name: 'owner',
            type: 'address',
          },
        ],
        name: 'balanceOf',
        outputs: [
          {
            internalType: 'uint256',
            name: '',
            type: 'uint256',
          },
        ],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [
          {
            internalType: 'uint256',
            name: 'tokenId',
            type: 'uint256',
          },
        ],
        name: 'getApproved',
        outputs: [
          {
            internalType: 'address',
            name: '',
            type: 'address',
          },
        ],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [],
        name: 'initialize',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        inputs: [
          {
            internalType: 'address',
            name: 'owner',
            type: 'address',
          },
          {
            internalType: 'address',
            name: 'operator',
            type: 'address',
          },
        ],
        name: 'isApprovedForAll',
        outputs: [
          {
            internalType: 'bool',
            name: '',
            type: 'bool',
          },
        ],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [
          {
            internalType: 'string',
            name: 'uri',
            type: 'string',
          },
        ],
        name: 'mint',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        inputs: [
          {
            internalType: 'address',
            name: 'recipient',
            type: 'address',
          },
          {
            internalType: 'string',
            name: 'uri',
            type: 'string',
          },
        ],
        name: 'mintNFT',
        outputs: [
          {
            internalType: 'uint256',
            name: '',
            type: 'uint256',
          },
        ],
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        inputs: [],
        name: 'name',
        outputs: [
          {
            internalType: 'string',
            name: '',
            type: 'string',
          },
        ],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [],
        name: 'owner',
        outputs: [
          {
            internalType: 'address',
            name: '',
            type: 'address',
          },
        ],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [
          {
            internalType: 'uint256',
            name: 'tokenId',
            type: 'uint256',
          },
        ],
        name: 'ownerOf',
        outputs: [
          {
            internalType: 'address',
            name: '',
            type: 'address',
          },
        ],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [],
        name: 'proxiableUUID',
        outputs: [
          {
            internalType: 'bytes32',
            name: '',
            type: 'bytes32',
          },
        ],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [],
        name: 'renounceOwnership',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        inputs: [
          {
            internalType: 'address',
            name: 'from',
            type: 'address',
          },
          {
            internalType: 'address',
            name: 'to',
            type: 'address',
          },
          {
            internalType: 'uint256',
            name: 'tokenId',
            type: 'uint256',
          },
        ],
        name: 'safeTransferFrom',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        inputs: [
          {
            internalType: 'address',
            name: 'from',
            type: 'address',
          },
          {
            internalType: 'address',
            name: 'to',
            type: 'address',
          },
          {
            internalType: 'uint256',
            name: 'tokenId',
            type: 'uint256',
          },
          {
            internalType: 'bytes',
            name: 'data',
            type: 'bytes',
          },
        ],
        name: 'safeTransferFrom',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        inputs: [
          {
            internalType: 'address',
            name: 'operator',
            type: 'address',
          },
          {
            internalType: 'bool',
            name: 'approved',
            type: 'bool',
          },
        ],
        name: 'setApprovalForAll',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        inputs: [
          {
            internalType: 'bytes4',
            name: 'interfaceId',
            type: 'bytes4',
          },
        ],
        name: 'supportsInterface',
        outputs: [
          {
            internalType: 'bool',
            name: '',
            type: 'bool',
          },
        ],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [],
        name: 'symbol',
        outputs: [
          {
            internalType: 'string',
            name: '',
            type: 'string',
          },
        ],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [],
        name: 'tokenId',
        outputs: [
          {
            internalType: 'uint256',
            name: '',
            type: 'uint256',
          },
        ],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [
          {
            internalType: 'uint256',
            name: 'id',
            type: 'uint256',
          },
        ],
        name: 'tokenURI',
        outputs: [
          {
            internalType: 'string',
            name: '',
            type: 'string',
          },
        ],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [
          {
            internalType: 'address',
            name: 'from',
            type: 'address',
          },
          {
            internalType: 'address',
            name: 'to',
            type: 'address',
          },
          {
            internalType: 'uint256',
            name: 'tokenId',
            type: 'uint256',
          },
        ],
        name: 'transferFrom',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        inputs: [
          {
            internalType: 'address',
            name: 'from',
            type: 'address',
          },
          {
            internalType: 'address',
            name: 'to',
            type: 'address',
          },
          {
            internalType: 'uint256',
            name: 'tokenId',
            type: 'uint256',
          },
        ],
        name: 'transferFromNFT',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        inputs: [
          {
            internalType: 'address',
            name: 'newOwner',
            type: 'address',
          },
        ],
        name: 'transferOwnership',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        inputs: [
          {
            internalType: 'address',
            name: 'newImplementation',
            type: 'address',
          },
        ],
        name: 'upgradeTo',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        inputs: [
          {
            internalType: 'address',
            name: 'newImplementation',
            type: 'address',
          },
          {
            internalType: 'bytes',
            name: 'data',
            type: 'bytes',
          },
        ],
        name: 'upgradeToAndCall',
        outputs: [],
        stateMutability: 'payable',
        type: 'function',
      },
    ],
    linkReferences: {},
    deployedLinkReferences: {},
  };
  // let contractAddress = "0x3F378a264564e94EE4f8225aE0172ac62Ed17B59";
  let contractAddress = '0x9497f3F66809BeeDC51C2e9c5914fbcC378f7939';

  // const navigate = useNavigate();
  const localCache = browserLocalstorage();
  const navigate = useNavigate();

  const calledOnce = useRef<boolean>(true);

  useEffect(() => {
    document.title = 'POAP (Logout)';

    if (calledOnce.current === false) return;
    const func = async () => {
      getAddressFromLocalStorage(setAddressFromHash).catch((e) => {
        console.log(e.message)
      });
      ethEnabled(setMessage, setMagicObj, locale).then(async (r) => {
        if (r) {
          ethGetAddress(setAddress, () => {});
        } else console.log('Seems no metamask');
      });
    };
    func();
    calledOnce.current = false;
  });

  const setApproval = async () => {
    if (window.web3) {
      window.web3.getBalance(address).then((balance: any) => {
        // convert a currency unit from wei to ether
        console.log(`balance: ${balance} ETH`);
      });

      const signer = await window.web3.getSigner();
      const PoemNFT = new ethers.Contract(contractAddress, contractABI.abi, signer);

      console.log('setApprovalForAll', signer);

      const log = await PoemNFT.setApprovalForAll('0x715f024db20c7ca80cc7a58c2977108aeb7c21aa', true, {
        gasLimit: 6521975,
      });
      console.log('setApprovalForAll', log);
    } else
      addToast(`Cannot use Metamask`, {
        appearance: 'warning',
        autoDismiss: true,
      });
  };

  const logout = async () => {
    if (magicObj !== undefined) {
      try {
        await magicObj.user.logout();
      } catch (e) {
        console.log('Magic connect isnt logged out');
      }
      window.location.href = window.location.href + '?' + Math.random().toString();
    } else
      addToast(`Please disconnect on your Metamask`, {
        appearance: 'warning',
        autoDismiss: true,
      });

    clearLocalStorage();
    clearMagicAuthLogin();

  };

  const open = async () => {
    if (magicObj !== undefined) magicObj.connect.showWallet();
    else
      addToast(`Magic is disconnected?`, {
        appearance: 'error',
        autoDismiss: true,
      });
  };

  useBodyClassName('poap-app');

  return (
    <>
      <div style={{fontFamily: "monospace"}}>
      magic: {address} <br />
      local: {addressFromHash && <>{addressFromHash}</>}
      </div>
      <hr />
      {message}
      <hr />      
      {magicObj && (
        <SubmitButton
          text={'open Magic'}
          onClick={open}
          style={{
            color: '#fff',
            backgroundColor: '#000',
            border: 'solid 2px white',
          }}
          isSubmitting={false}
          canSubmit
        />
      )}
      <br />
      <SubmitButton
        text={'Logout'}
        onClick={logout}
        style={{
          color: '#fff',
          backgroundColor: '#000',
          border: 'solid 2px white',
        }}
        isSubmitting={false}
        canSubmit
      />
      {window.web3 && (
        <SubmitButton
          text={'Set approval'}
          onClick={setApproval}
          style={{
            color: '#fff',
            backgroundColor: '#000',
            border: 'solid 2px white',
          }}
          isSubmitting={false}
          canSubmit
        />
      )}
      {address.startsWith('0x') && (
        <SubmitButton
          text={'To my collection'}
          onClick={() => {
            navigate('/o/scan/' + address);
          }}
          style={{
            color: '#fff',
            backgroundColor: '#000',
            border: 'solid 2px white',
          }}
          isSubmitting={false}
          canSubmit
        />
      )}
    </>
  );
};

import React, { useEffect, useState } from 'react';
import { useI18nContext } from '@/i18n/i18n-react';

import classNames from 'classnames';

/* Helpers */
import {
  getIncClaimHash,
  getIncTokenInfo,
  getMagicUsed,
  HashClaim,
  postIncClaimUpdate,
  postIncEmail,
  sendThanks,
  Template,
  TemplatePageFormValues,
  TokenInfo,
} from '@/api';

/* Components */
import { FormikHelpers } from 'formik';
import { useNavigate } from 'react-router-dom';

import ReactGA4 from 'react-ga4';
import PoapImage from '../components/PoapImage';

import EmailForm from '../components/EmailForm';

import PoapInfo from '@/components/PoapInfo';
import Waiting from '@/components/Waiting';
import { reduceAddress } from '@/lib/helpers';
import { Loading } from '@/components/Loading';
import browserLocalstorage from 'browser-localstorage-expire';

type QRFormValues = {
  email: string;
};

type FormikStatusValues = {
  ok: boolean;
  msg: string;
};

/*
 * @dev: Component to show minted token
 * */
const ClaimFinished: React.FC<{ claim: HashClaim; template?: Template | TemplatePageFormValues }> = ({
  claim,
  template,
}) => {
  const navigate = useNavigate();

  const [email, setEmail] = useState<string>('');
  const [address, setAddress] = useState<string>('');
  const [emailRegistered, setEmailRegistered] = useState<boolean>(true);
  const [emailSent, setEmailSent] = useState<boolean>(false);
  const [formikStatus, setFormikStatus] = useState<FormikStatusValues>();
  // const mainColor = claim?.event_template?.main_color ?? template?.main_color;
  const [token, setToken] = useState<null | TokenInfo>(null);

  const [showMessage, setShowMessage] = useState<boolean>(false);
  const [transparent, setTransparent] = useState<boolean>(false);

  const { locale, LL, setLocale } = useI18nContext();

  const localCache = browserLocalstorage();

  useEffect(() => {
    document.title = 'POEM (Claim completed)';

    // if (claim.claimed) {
      // (1) Getting token supply info (12/99 etc)
      const getToken = async () => {
        if (claim.result) {
          try {
            await new Promise((resolve) => setTimeout(resolve, 3000));
            var token = await getIncTokenInfo(parseInt(claim.result.token.toString()));
            setToken(token);
            postIncClaimUpdate(claim).then((v) => {
              console.log("dbUpdate!!!!!")
              // setDbUpdate(true);
            });
            localCache.setItem('incHash', claim.qr_hash, 7 * 24 * 60);
          } catch (e) {
            console.error('Cannot get token details');
          }
        }
      };
      setShowMessage(true);
      setTransparent(true);
      var myInterval = setInterval(async () => {
        claim = await getIncClaimHash(claim.qr_hash)
        if ('result' in claim) {
          console.log("intervalCleared");
          clearInterval(myInterval);
      
          setAddress(claim.beneficiary);
          getToken();
          try {
            const pRes = getMagicUsed(claim.beneficiary);
            pRes.then((resJson) => {
              if (!emailSent) {
                if (resJson.email) {
                  console.log(`email found with ${reduceAddress(claim.beneficiary)} and it has ${resJson.count}`);
                  setEmailRegistered(true);
                  // this isnt 1st POAP for the user. Send Thanks when the row has no email
                  sendThanks({
                    hash: claim.qr_hash,
                    address: claim.beneficiary,
                    eventname: claim.event.name,
                    imageurl: claim.event.image_url,
                    sendanyway: false,
                    language: locale,
                  });
                  setEmailSent(true);
                } else setEmailRegistered(false);
              }
            });
          } catch (e) {
            console.log('magic isnt used.');
          }
        }    
        
      }, 1000);

      // (2) Getting the event info (ja) from our db
      // const pRes = getEventInfo(claim.event_id.toString());
      // pRes
      //   .then((v) => {
      //     setEventInfo(v);
      //   })
      //   .catch((e) => {
      //     console.error('getEventInfo', e);
      //   });
      // (3) checking the user's email is registered.
    // }
  }, []); // eslint-disable-line

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowMessage(false);
    }, 6000);
    return () => clearTimeout(timer);
  }, [transparent]);

  const emailSubmit = async (values: QRFormValues, actions: FormikHelpers<QRFormValues>) => {
    try {
      actions.setSubmitting(true);
      if (!emailSent) {
        // Add email on the claim first
        const status: HashClaim = await postIncEmail({
          email: values.email,
          address: claim.beneficiary,
          hash: claim.qr_hash,
          eventname: claim.event.name,
          imageurl: claim.event.image_url,
        });
        // Send email anyway
        await sendThanks({
          hash: claim.qr_hash,
          address: claim.beneficiary,
          eventname: claim.event.name,
          imageurl: claim.event.image_url,
          sendanyway: true,
          language: locale,
        });
        ReactGA4.event({
          category: 'email',
          action: 'thanks',
          label: 'sent', //values.email, // optional
          value: 1,
        });
        console.log('Thanks mail is sent');
      } else {
        console.log('Thanks mail is already sent.');
      }
      setEmailSent(true);
      //
      console.log(`${values.email} is set with ${claim.qr_hash}`);
      setEmail(values.email);
      //
      // navigate(`/o/scan/${claim.beneficiary}`, { replace: true });
      setEmailRegistered(true);
    } catch (error: any) {
      console.log(error);
      setFormikStatus({ ok: true, msg: '!' });
      actions.setSubmitting(false);
    }
  };

  return (
    <div className={'container'}>
      {emailRegistered === true && (
        <>
          <PoapImage claim={claim} active={true} animation={true} avColor={claim.event.image_av_color}>
            <div id={'js-msg'} className={classNames('message-event', showMessage && 'is-active')}>
              {LL.CLAIMED_MSG()}
            </div>
          </PoapImage>
          {token ? 
            <PoapInfo eventInfo={token.event} tokenId={parseInt(token.tokenId)} supply={token?.supply} />
          : claim &&
            <PoapInfo eventInfo={claim.event} tokenId={-1} supply={undefined} />
          }
          <button
            className={'btn link-btn'}
            type="submit"
            disabled={token ? false: true}
            onClick={() => {
              navigate(`/o/scan/${address}`, { replace: true });
            }}
          >
            {/* { spriteSvg } */}
            <span>{LL.LIST_BUTTON()}</span>
          </button>
        </>
      )}
      {emailRegistered === false && (
        <>
          <div className="inner">
            <PoapImage claim={claim} active={false} animation={false} avColor={claim.event.image_av_color}/>
            <div className="cancel-text01">{LL.EMAIL_NEEDED()}</div>
            <div className="cancel-text02">{LL.EMAIL_NEEDED_MSG()}</div>
            <EmailForm email={email} handle={emailSubmit} formikStatus={formikStatus} />
          </div>
        </>
      )}
    </div>
  );
};

export default ClaimFinished;
